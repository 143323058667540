const subcategoryApi = {
  GET_ALL_CUSTOMCATEGORIES: `/customcategories`,
  CREATE_CUSTOMCATEGORIES: '/customcategories/create',
  UPDATE_CUSTOMCATEGORIES: '/customcategories/update',
  GET_CUSTOMCATEGOEY_IMAGES: '/customcategories/getimages',
  UPLOAD_CUSTOMCATEGORY_IMAGES: '/customcategories/uploadimages',
  DELETE_CUSTOMCATEGORIES: '/customcategories/delete',
  SEND_NOTIFICATION: '/customcategories/notify'
};
export default subcategoryApi;
