import { IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import {
  getAllFestivals,
  createFestival,
  deleteFestival,
  updateFestival,
  sendNotification,
} from "../../redux/action/festivalActions";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import { festivalTypes } from "../../redux/types";
const Festivals = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.festival.festivals);

  const [isDate, setIsDate] = useState(true);

  const formData = [
    {
      type: "text",
      name: "festivalName",
      label: "Festival Name",
      placeholder: "Type Festival Name",
      required: true,
      size: 12,
    },
    {
      type: "switch",
      name: "isFixedDate",
      label: "Is fixed date",
      placeholder: "Type Festival Name",
      required: true,
      size: 4,
    },
    {
      type: isDate ? "date" : "none",
      name: "festivalDate",
      label: "Festival Date",
      placeholder: "Type Festival Date",
      required: true,
      size: 4,
    },

    // {
    //   type: "date",
    //   name: "festivalDate",
    //   label: "Festival Date",
    //   placeholder: "Type Festival Date",
    //   size: 6,
    // },
    {
      type: "select",
      name: "status",
      size: 6,
      label: "Status",
      options: [
        {
          title: "Active",
          value: "true",
        },
        {
          title: "Inactive",
          value: "false",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
      required: true,
    },
    {
      type: "textarea",
      name: "festivalDescription",
      label: "Description",
      placeholder: "Type Description",
      size: 12,
    },
    {
      type: "file",
      name: "festivalImages",
      multiple: true,
      label: "Festival Image",
      required: true,
      size: 12,
    },
  ];

  const handleNotify = (data) => {
    dispatch(sendNotification(data));
  };

  const NotificationAction = (action) => {
    return (
      <Tooltip placement="top" title="Send Notification">
        <IconButton color="primary" onClick={() => handleNotify(action.data)}>
          <AddAlertIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const loadingTypes = [
    festivalTypes.CREATE_FESTIVALS,
    festivalTypes.UPDATE_FESTIVALS,
  ];

  const tableHeaders = [
    { title: "Images", key: "festivalImages", type: "image" },
    { title: "Name", key: "festivalName" },
    { title: "Date", key: "festivalDate", type: "date" },
    { title: "Status", key: "status" },
  ];

  const pageProps = {
    title: "Festivals",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["festivalName"],
    searchByLabel: "name",
    modalSize: "md",
    sortable: true,

    paginated: true,

    enableImport: false,
    importHeaders: [],

    importData: [],

    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    tableRowActions: [NotificationAction],
    pageHeaderActions: [],

    defaultFormValues: {},
    deleteVariableTitle: undefined,
    onAdd: createFestival,
    onEdit: updateFestival,
    onDelete: deleteFestival,
    onImport: () => {},
    watchFields: ["isFixedDate"],
    onWatchChange: (data) => {
      setIsDate(data.isFixedDate);
    },
    getData: (e) => getAllFestivals(),
    getImportData: () => {},
    afterAddSuccess: () => {},
    afterEditSuccess: () => {},
    afterDeleteSuccess: () => {},
    afterImportSuccess: () => {},
    loadingTypes: loadingTypes,
  };
  React.useEffect(() => {
    dispatch(getAllFestivals());
  }, []);
  return (
    <div>
      <PageCreator {...pageProps} />
    </div>
  );
};

export default Festivals;
