import { sliderTypes } from "../types";

const initialstate = {
  sliders: [],
};

const sliderReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case sliderTypes.GET_ALL_SLIDERS_SUCCESS:
      return {
        ...state,
        sliders: getData()?.data,
      };
    default:
      return state;
  }
};

export default sliderReducer;
