import { frameTypes } from "../types";

const initialstate = {
  frames: [],
  frameImage: undefined
};

const frameReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case frameTypes.GET_ALL_FRAMES_SUCCESS:
      return {
        ...state,
        frames: getData()?.data,
      };

    case frameTypes.UPLOAD_FRAMES_SUCCESS:
      return {
        ...state,
        frameImage: getData()?.data,
      };

    default:
      return state;
  }
};

export default frameReducer;
