import frameFieldsApi from "../api/frameFieldsApi";
import { frameFieldsTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";

export const getFrameFields = (cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get frames",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameFieldsTypes.GET_ALL_FRAME_FIELDS,
      payload: {
        request: {
          url: frameFieldsApi.GET_ALL_FRAME_FIELDS,
          method: "GET",
        },
      },
    });
};

export const createFrameFields = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    formData.append(key, data[key])
    if (key === 'image') {
      if (Array.isArray(data.image)) { formData.append(key, data.image[0]) }
    }

    else {

      if (Array.isArray(data.image)) {
        return
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame created successfully",
      errorMessage: "Failed to create frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameFieldsTypes.CREATE_FRAME_FIELDS,
      payload: {
        request: {
          url: frameFieldsApi.CREATE_FRAME_FIELDS,
          method: "POST",
          data: formData
        },
      },
    });
};

export const updateFrameFields = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    formData.append(key, data[key])
    if (key === 'image') {
      if (Array.isArray(data.image)) { formData.append(key, data.image[0]) }
    }
    else {

      if (Array.isArray(data.image)) {
        return
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame created successfully",
      errorMessage: "Failed to update frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameFieldsTypes.UPDATE_FRAME_FIELDS,
      payload: {
        request: {
          url: frameFieldsApi.UPDATE_FRAME_FIELDS,
          method: "PUT",
          data: formData
        },
      },
    });
};

export const deleteFrameFields = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame deleted successfully",
      errorMessage: "Failed to delete frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameFieldsTypes.DELETE_FRAME_FIELDS,
      payload: {
        request: {
          url: frameFieldsApi.DELETE_FRAME_FIELDS,
          method: "GET",
          params: { id: data.id }
        },
      },
    });
};

