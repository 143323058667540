import { subcategoryTypes } from "../types";

const initialstate = {
  subcategories: [],
  subImages: []
};

const subcategoryReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case subcategoryTypes.GET_ALL_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: getData()?.data,
      };

    case subcategoryTypes.GET_SUBCATEGOEY_IMAGES_SUCCESS:
      return {
        ...state,
        subImages: getData()?.data,
      };
    default:
      return state;
  }
};

export default subcategoryReducer;
