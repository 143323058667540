import { themeSampleTypes } from "../types";

const initialstate = {
  themeSamples: [],
};

const themeSampleReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case themeSampleTypes.GET_ALL_THEME_SAMPLES_SUCCESS:
      return {
        ...state,
        themeSamples: getData()?.data,
      };
    default:
      return state;
  }
};

export default themeSampleReducer;
