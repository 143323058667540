import subcategoryApi from "../api/subcategoryApi";
import { subcategoryTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllSubcategories = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get subcategories",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subcategoryTypes.GET_ALL_SUBCATEGORIES,
      payload: {
        request: {
          url: subcategoryApi.GET_ALL_SUBCATEGORIES,
          method: "GET",
          params: { id: data }
        },
      },
    });
};

export const createSubcategory = (data, cb, errorCb) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key) => {
  //   if (key === 'subcategoryImage') {
  //     if (data['subcategoryImage'].length > 0) {
  //       console.log('data[subcategoryImage].length', data['subcategoryImage'].length);
  //       data['subcategoryImage'].forEach((item, index) => {
  //         formData.append(`subcategoryImage`, data['subcategoryImage'][index])
  //       })
  //     } else {
  //       formData.delete('subcategoryImage')
  //     }
  //   } else {
  //     formData.append(key, data[key])
  //   }

  // });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subcategory created successfully",
      errorMessage: "Failed to create subcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subcategoryTypes.CREATE_SUBCATEGORIES,
      payload: {
        request: {
          url: subcategoryApi.CREATE_SUBCATEGORIES,
          method: "POST",
          data: data
        },
      },
    });
};

export const updateSubcategory = (data, cb, errorCb) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key, i) => {
  //   formData.append(key, data[key])
  //   if (key === 'subcategoryImage') {
  //     if (Array.isArray(data.subcategoryImage)) { formData.append(key, data.subcategoryImage[0]) }
  //   }
  // });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subcategory created successfully",
      errorMessage: "Failed to update subcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subcategoryTypes.UPDATE_SUBCATEGORIES,
      payload: {
        request: {
          url: subcategoryApi.UPDATE_SUBCATEGORIES,
          method: "PUT",
          data: data
        },
      },
    });
};

export const getSubCateImages = (data, cb, errorCb) => {
  return {
    type: subcategoryTypes.GET_SUBCATEGOEY_IMAGES,
    payload: {
      request: {
        url: subcategoryApi.GET_SUBCATEGOEY_IMAGES,
        method: "GET",
        params: { id: data }
      },
    },
  };
}


export const uploadSubcategoryImages = (data, cb, errorCb) => {
  const formData = new FormData();

  data.images.forEach((k) => {
    formData.append('images', k)
  })
  formData.append('id', data.id)
  formData.append('categoryId', data.categoryId)


  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subcategory images added  successfully",
      errorMessage: "Failed to add subcategory images",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subcategoryTypes.UPLOAD_SUBCATEGORY_IMAGES,
      payload: {
        request: {
          url: subcategoryApi.UPLOAD_SUBCATEGORY_IMAGES,
          method: "POST",
          data: formData
        },
      },
    });
};

export const deleteSubcategory = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subcategory deleted successfully",
      errorMessage: "Failed to delete subcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subcategoryTypes.DELETE_SUBCATEGORIES,
      payload: {
        request: {
          url: subcategoryApi.DELETE_SUBCATEGORIES,
          method: "DELETE",
          data: data
        },
      },
    });
};

export const sendNotification = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Notification sent Successfully",
      errorMessage: "Failed to send notification",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subcategoryTypes.SEND_NOTIFICATION,
      payload: {
        request: {
          url: subcategoryApi.SEND_NOTIFICATION,
          method: "post",
          data: data,
        },
      },
    });
};
