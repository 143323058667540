import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCommonAction from "../../components/Common/Actions/AddCommonAction";
import DeleteCommonAction from "../../components/Common/Actions/DeleteCommonAction";
import EditCommonAction from "../../components/Common/Actions/EditAction";
import DeleteModal from "../../components/Common/Modals/CommonDeleteModal";
import FrameActionModal from '../../components/Common/Modals/FrameActionModal'
import SmartTable from '../../components/Common/SmartTable/tabularTable'
import PageTitle from "../../components/PageCreator/PageTtitle";
import { createFrame, deleteFrame, getAllFrames, updateFrame } from "../../redux/action/frameActions";
import { getFrameFields } from "../../redux/action/frameFieldsActions";

const Frames = () => {
    const [actionData, setActionData] = React.useState();
    const data = useSelector(state => state.frame.frames)
    const [open, setOpen] = React.useState();
    const title = "Frames"
    const dispatch = useDispatch()

    const tableHeaders = [
        { title: "Name", key: "frameName", },
        { title: "Frame", key: "path", type: 'image' },
        { title: "Status", key: "status" },
    ];

    const toggleAdd = (mode) => {
        setOpen(mode);
        if (mode === undefined) {
            setActionData({});
        }
    };

    const handleRowAction = (mode, data) => {
        toggleAdd(mode);
        setActionData(data);
    };

    const onAdd = (data) => {
        if (open === "Add") {
            dispatch(
                createFrame(
                    data, () => {
                        toggleAdd();
                        dispatch(getAllFrames());
                    }, []
                )
            );
        }
        if (open === "Edit") {
            let editData = {
                ...actionData,
                ...data,
            };
            dispatch(
                updateFrame(editData, () => {
                    toggleAdd();
                    dispatch(getAllFrames());
                })
            );
        }
        if (open === "Delete") {
            dispatch(
                deleteFrame(actionData._id || actionData.id, () => {
                    toggleAdd();
                    setActionData({});
                    dispatch(getAllFrames());
                })
            );
        }
    };
    const AddAction = () => (
        <AddCommonAction onClick={() => handleRowAction("Add")} title={title} />
    );
    const EditAction = (action) => (
        <EditCommonAction onClick={() => handleRowAction("Edit", action.data)} />
    );

    const DeleteAction = (action) => (
        <DeleteCommonAction onClick={() => handleRowAction("Delete", action.data)} />
    );

    React.useEffect(() => {
        dispatch(getAllFrames())
        dispatch(getFrameFields())
    }, [])
    return (
        <>
            {title && (
                <PageTitle
                    title={title}
                    endAction={AddAction}
                />
            )}
            <FrameActionModal
                title={title}
                open={open === "Add" || open === "Edit"}
                onClose={() => toggleAdd()}
                mode={open}
                onSubmit={(e) => onAdd(e)}
                data={actionData}
                size="md"
            />

            <DeleteModal
                size="md"
                open={open === "Delete"}
                onClose={() => toggleAdd()}
                onConfirm={() => onAdd()}
            />
            <SmartTable
                tableTitle={title}
                actions={[EditAction, DeleteAction]}
                tableData={data}
                header={tableHeaders}
                sortable={true}
                paginated={true}
                searchByLabel={"Frame Name"}
                searchByField={"frameName"}
                rowsPerPage={5}
            />
        </>
    );
};

export default Frames;