import { festivalTypes } from "../types";

const initialstate = {
  festivals: [],
};

const festivalReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case festivalTypes.GET_ALL_FESTIVALS_SUCCESS:
      return {
        ...state,
        festivals: getData()?.data,
      };
    default:
      return state;
  }
};

export default festivalReducer;
