import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  header: {
    textAlign: "center",
    margin: theme.spacing(5, 0, 2, 0),
    fontWeight: theme.palette.fontWeights.bold,
  },
  ul: {
    margin: theme.spacing(5, 0),
    marginLeft: 50,
  },
  title: {
    fontSize: theme.palette.fontSizes.lg,
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    margin: theme.spacing(3, 0),
  },
}));

const Terms = () => {
  const classes = useStyles();
  const [sticky, setSticky] = React.useState();
  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        Terms and Conditions
      </Typography>

      <Typography className={classes.text}>
        Welcome to Panchayat Post!
      </Typography>

      <Typography className={classes.text}>
        These terms and conditions outline the rules and regulations for the use
        of Panchayat Post.
      </Typography>

      <Typography className={classes.text}>
        By using this app we assume you accept these terms and conditions. Do
        not continue to use Panchayat Post if you do not agree to take all of
        the terms and conditions stated on this page.
      </Typography>

      <Typography className={classes.text}>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: &quot;Client&quot;,
        &quot;You&quot; and &quot;Your&quot; refers to you, the person log on
        this website and compliant to the Company’s terms and conditions.
        &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;,
        &quot;Our&quot; and &quot;Us&quot;, refers to our Company.
        &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers to
        both the Client and ourselves. All terms refer to the offer, acceptance
        and consideration of payment necessary to undertake the process of our
        assistance to the Client in the most appropriate manner for the express
        purpose of meeting the Client’s needs in respect of provision of the
        Company’s stated services, in accordance with and subject to, prevailing
        law of Netherlands. Any use of the above terminology or other words in
        the singular, plural, capitalization and/or he/she or they, are taken as
        interchangeable and therefore as referring to same. Our Terms and
        Conditions were created with the help of the&nbsp;
        <a href="https://www.app-privacy-policy.com/app-terms-conditions-generator/">
          App Terms and Conditions Generator from App-Privacy-Policy.com
        </a>
      </Typography>

      <Typography className={classes.title}>License</Typography>

      <Typography className={classes.text}>
        Unless otherwise stated, Panchayat Post and/or its licensors own the
        intellectual property rights for all material on Panchayat Post. All
        intellectual property rights are reserved. You may access this from
        Panchayat Post for your own personal use subjected to restrictions set
        in these terms and conditions.
      </Typography>

      <Typography className={classes.text}>You must not:</Typography>

      <ul className={classes.ul}>
        <li className={classes.text}>Republish material from Panchayat Post</li>
        <li className={classes.text}>
          Sell, rent or sub-license material from Panchayat Post
        </li>
        <li className={classes.text}>
          Reproduce, duplicate or copy material from Panchayat Post
        </li>
        <li className={classes.text}>
          Redistribute content from Panchayat Post
        </li>
      </ul>

      <Typography className={classes.text}>
        This Agreement shall begin on the date hereof.
      </Typography>

      <Typography className={classes.text}>
        Parts of this app offer an opportunity for users to post and exchange
        opinions and information in certain areas of the website. Panchayat Post
        does not filter, edit, publish or review Comments prior to their
        presence on the website. Comments do not reflect the views and opinions
        of Panchayat Post, its agents and/or affiliates. Comments reflect the
        views and opinions of the person who post their views and opinions. To
        the extent permitted by applicable laws, Panchayat Post shall not be
        liable for the Comments or for any liability, damages or expenses caused
        and/or suffered as a result of any use of and/or posting of and/or
        appearance of the Comments on this website.
      </Typography>

      <Typography className={classes.text}>
        Panchayat Post reserves the right to monitor all Comments and to remove
        any Comments which can be considered inappropriate, offensive or causes
        breach of these Terms and Conditions.
      </Typography>

      <Typography className={classes.text}>
        You warrant and represent that:
      </Typography>

      <ul className={classes.ul}>
        <li className={classes.text}>
          You are entitled to post the Comments on our App and have all
          necessary licenses and consents to do so;{" "}
        </li>
        <li className={classes.text}>
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;{" "}
        </li>
        <li className={classes.text}>
          The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful className={classes.ul}material which is
          an invasion of privacy
        </li>
        <li className={classes.text}>
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful className={classes.ul}
          activity.
        </li>
      </ul>

      <Typography className={classes.text}>
        You hereby grant Panchayat Post a non-exclusive license to use,
        reproduce, edit and authorize others to use, reproduce and edit any of
        your Comments in any and all forms, formats or media.
      </Typography>

      <Typography className={classes.title}>Hyperlinking to our App</Typography>

      <Typography className={classes.text}>
        The following organizations may link to our App without prior written
        approval:
      </Typography>

      <ul className={classes.ul}>
        <li className={classes.text}>Government agencies; </li>
        <li className={classes.text}>Search engines; </li>
        <li className={classes.text}>News organizations; </li>
        <li className={classes.text}>
          Online directory distributors may link to our App in the same manner
          as they hyperlink to the Websites of other listed businesses; and
        </li>
        <li className={classes.text}>
          System wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our Web site.
        </li>
      </ul>

      <Typography className={classes.text}>
        These organizations may link to our home page, to publications or to
        other App information so long as the link: (a) is not in any way
        deceptive; (b) does not falsely imply sponsorship, endorsement or
        approval of the linking party and its products and/or services; and (c)
        fits within the context of the linking party’s site.
      </Typography>

      <Typography className={classes.text}>
        We may consider and approve other link requests from the following types
        of organizations:
      </Typography>

      <ul className={classes.ul}>
        <li className={classes.text}>
          commonly-known consumer and/or business information sources;{" "}
        </li>
        <li className={classes.text}>dot.com community sites; </li>
        <li className={classes.text}>
          associations or other groups representing charities;{" "}
        </li>
        <li className={classes.text}>online directory distributors; </li>
        <li className={classes.text}>internet portals; </li>
        <li className={classes.text}>
          accounting, law and consulting firms; and
        </li>
        <li className={classes.text}>
          educational institutions and trade associations.
        </li>
      </ul>

      <Typography className={classes.text}>
        We will approve link requests from these organizations if we decide
        that: (a) the link would not make us look unfavorably to ourselves or to
        our accredited businesses; (b) the organization does not have any
        negative records with us; (c) the benefit to us from the visibility of
        the hyperlink compensates the absence of Panchayat Post; and (d) the
        link is in the context of general resource information.
      </Typography>

      <Typography className={classes.text}>
        These organizations may link to our App so long as the link: (a) is not
        in any way deceptive; (b) does not falsely imply sponsorship,
        endorsement or approval of the linking party and its products or
        services; and (c) fits within the context of the linking party’s site.
      </Typography>

      <Typography className={classes.text}>
        If you are one of the organizations listed in paragraph 2 above and are
        interested in linking to our App, you must inform us by sending an
        e-mail to Panchayat Post. Please include your name, your organization
        name, contact information as well as the URL of your site, a list of any
        URLs from which you intend to link to our App, and a list of the URLs on
        our site to which you would like to link. Wait 2-3 weeks for a response.
      </Typography>

      <Typography className={classes.text}>
        Approved organizations may hyperlink to our App as follows:
      </Typography>

      <ul className={classes.ul}>
        <li className={classes.text}>By use of our corporate name; or</li>
        <li className={classes.text}>
          By use of the uniform resource locator being linked to; or
        </li>
        <li className={classes.text}>
          By use of any other description of our App being linked to that makes
          sense within the context and format of content on the linking party’s
          site.
        </li>
      </ul>

      <Typography className={classes.text}>
        No use of Panchayat Post's logo or other artwork will be allowed for
        linking absent a trademark license agreement.
      </Typography>

      <Typography className={classes.title}>iFrames</Typography>

      <Typography className={classes.text}>
        Without prior approval and written permission, you may not create frames
        around our Webpages that alter in any way the visual presentation or
        appearance of our App.
      </Typography>

      <Typography className={classes.title}>Content Liability</Typography>

      <Typography className={classes.text}>
        We shall not be hold responsible for any content that appears on your
        App. You agree to protect and defend us against all claims that is
        rising on our App. No link(s) should appear on any Website that may be
        interpreted as libelous, obscene or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of,
        any third party rights.
      </Typography>

      <Typography className={classes.title}>Your Privacy</Typography>

      <Typography className={classes.text}>
        Please read Privacy Policy.
      </Typography>

      <Typography className={classes.title}>Reservation of Rights</Typography>

      <Typography className={classes.text}>
        We reserve the right to request that you remove all links or any
        particular link to our App. You approve to immediately remove all links
        to our App upon request. We also reserve the right to amen these terms
        and conditions and it’s linking policy at any time. By continuously
        linking to our App, you agree to be bound to and follow these linking
        terms and conditions.
      </Typography>

      <Typography className={classes.title}>
        Removal of links from our App
      </Typography>

      <Typography className={classes.text}>
        If you find any link on our App that is offensive for any reason, you
        are free to contact and inform us any moment. We will consider requests
        to remove links but we are not obligated to or so or to respond to you
        directly.
      </Typography>

      <Typography className={classes.text}>
        We do not ensure that the information on this website is correct, we do
        not warrant its completeness or accuracy; nor do we promise to ensure
        that the website remains available or that the material on the website
        is kept up to date.
      </Typography>

      <Typography className={classes.title}>Disclaimer</Typography>

      <Typography className={classes.text}>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our App and the
        use of this website. Nothing in this disclaimer will:
      </Typography>

      <ul className={classes.ul}>
        <li className={classes.text}>
          limit or exclude our or your liability for death or personal injury;{" "}
        </li>
        <li className={classes.text}>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;{" "}
        </li>
        <li className={classes.text}>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li className={classes.text}>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
      </ul>

      <Typography className={classes.text}>
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort and for breach of
        statutory duty.
      </Typography>

      <Typography className={classes.text}>
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </Typography>

      <Typography className={classes.text}>&nbsp;</Typography>
    </div>
  );
};
export default Terms;
