import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import {
  getFrameFields,
  createFrameFields,
  updateFrameFields,
  deleteFrameFields,
} from "../../redux/action/frameFieldsActions";

const FrameFields = () => {
  const data = useSelector((state) => state.framefield.fields);
  const [isFile, setIsFile] = React.useState(false);
  const formData = [
    {
      type: "text",
      name: "title",
      label: "Title",
      placeholder: "Type title",
      required: true,
      size: 12,
    },
    {
      type: "text",
      name: "variableName",
      label: "Variable",
      placeholder: "Variable",
      required: false,
      size: 12,
    },
    {
      type: "select",
      name: "type",
      label: "Type",
      onSelect: (val) => {
        if (val === "image") setIsFile(true);
        else setIsFile(false);
      },
      options: [
        {
          title: "Text",
          value: "text",
        },
        {
          title: "Image",
          value: "image",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
      required: true,
      size: 6,
      required: true,
    },
    {
      type: "switch",
      name: "isElection",
      label: "Is Election",
      placeholder: "Type Post Name",
      required: true,
      size: 6,
      required: true,
    },
    {
      type: isFile ? "file" : "none",
      name: "image",
      label: "Image",
      placeholder: "Type Image",
      required: true,
      size: 12,
    },
  ];

  const tableHeaders = [
    { title: "Title", key: "title" },
    { title: "Type", key: "type" },
    { title: "Image", key: "image", type: "image" },
    { title: "Variable Name", key: "variableName", },
    { title: "Election", key: "isElection", type: "status" },
  ];

  const pageProps = {
    title: "Frame Fields",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["title"],
    searchByLabel: "title",
    modalSize: "sm",
    sortable: true,
    paginated: true,
    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    onEditModal: (data) => {
      console.log("edit", data);
      if (data.type === "image") setIsFile(true);
      else setIsFile(false);
    },
    onModalClose: () => {
      setIsFile(false);
    },
    onAdd: createFrameFields,
    onEdit: updateFrameFields,
    onDelete: deleteFrameFields,
    getData: getFrameFields,
  };

  return <PageCreator {...pageProps} />;
};

export default FrameFields;
