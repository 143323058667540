import { postTypes } from "../types";

const initialstate = {
  posts: [],
  festivalPost: [],
  festivals: []
};

const postReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case postTypes.GET_ALL_POSTS_SUCCESS:
      return {
        ...state,
        posts: getData()?.data,
      };

    case postTypes.GET_FESTIVAL_POST_SUCCESS:
      return {
        ...state,
        festivalPost: getData()?.data.post,
        festivals: getData()?.data.festivals,
      };


    default:
      return state;
  }
};

export default postReducer;
