import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import {
  getAllCategories,
  createCategory,
  deleteCategory,
  updateCategory,
} from "../../redux/action/categoryActions";
import SubcategoryIcon from "@material-ui/icons/SubjectTwoTone";
import CommonAction from "../../components/Common/Actions/CommonAction";
import { useHistory } from "react-router";
import { Badge } from "@material-ui/core";
import { categoryTypes } from "../../redux/types";

const Categories = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.category.categories);
  const history = useHistory();

  const CommonActionBtn = (action) => (
    <CommonAction
      color="primary"
      onClick={() => {
        history.push(`/subcategories/${action.data._id || action.data.id}`);
      }}
      title="View Subcategories"
      children={
        <Badge badgeContent={action.data.totalSubcategories} color="primary">
          <SubcategoryIcon />
        </Badge>
      }
    />
  );

  const formData = [
    {
      type: "text",
      name: "categoryName",
      label: "Category Name",
      placeholder: "Type Category Name",
      required: true,
      size: 12,
    },
    {
      type: "switch",
      name: "isElection",
      label: "Is Election",
      required: true,
      size: 12,
    },
    {
      type: "select",
      name: "status",
      size: 12,

      label: "Status",
      options: [
        {
          title: "Active",
          value: "true",
        },
        {
          title: "Inactive",
          value: "false",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
      required: true,
    },
  ];

  const tableHeaders = [
    { title: "Category Name", key: "categoryName" },
    { title: "Status", key: "status" },
  ];

  const pageProps = {
    title: "Categories",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["categoryName"],
    searchByLabel: "name",
    modalSize: "xs",
    sortable: true,

    paginated: true,

    enableImport: false,
    importHeaders: [],

    importData: [],

    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    tableRowActions: [CommonActionBtn],
    pageHeaderActions: [],

    defaultFormValues: {},
    deleteVariableTitle: undefined,
    onAdd: createCategory,
    onEdit: updateCategory,
    onDelete: deleteCategory,
    onImport: () => {},

    getData: (e) => getAllCategories(),
    getImportData: () => {},
    afterAddSuccess: () => {},
    afterEditSuccess: () => {},
    afterDeleteSuccess: () => {},
    afterImportSuccess: () => {},
    loadingTypes: [
      categoryTypes.CREATE_CATEGORIES,
      categoryTypes.UPDATE_CATEGORIES,
    ],
  };
  React.useEffect(() => {
    dispatch(getAllCategories());
  }, []);
  return (
    <div>
      <PageCreator {...pageProps} />
    </div>
  );
};

export default Categories;
