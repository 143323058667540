import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  CircularProgress,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Typography,
  TextField,
  MenuItem,
  FormHelperText,
  Switch,
  Slide,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { DropzoneArea } from "material-ui-dropzone";
import { BASEIMAGEURL, FONTFAMILY, FONTSIZES, FONTWEIGHTS } from "../../../contants";
import { showSnackBar } from "../../../redux/action/snackActions";
import { uploadFrame } from "../../../redux/action/frameActions";

import RegionSelect from "react-region-select";
import clsx from "clsx";
import { ColorPicker } from "material-ui-color";
import { getFrameFields } from "../../../redux/action/frameFieldsActions";

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    fontSize: theme.palette.fontSizes.md,
    color: theme.palette.text.primary,
  },
  toolBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      margin: "0 5px",
    },
  },
  img: {
    width: "100%",
    border: `3px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.palette.radius.base,
  },
  error: {
    color: theme.palette.error.main,
  },
  errorRegion: {
    border: `3px dashed ${theme.palette.error.main}`,
  },
  appBar: {
    position: "relative",
    borderBottom: `1px solid ${theme.palette.gray[700]}`,
    padding: "10px 0",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(255, 255, 255, 0.72)",
    width: "100%",
  },
}));
const FrameActionModal = ({
  open,
  onClose,
  title,
  size,
  data = {},
  festivals,
  mode,
  onDelete,
  onSubmit,
}) => {
  const imageRef = React.useRef();
  const classes = useStyles();
  const isLoading = useSelector((state) => state.util.spinner);
  const initialValues = {
    frameName: undefined,
    status: undefined,
    isElection: false,
    errors: {},
    regions: [],
    color: undefined,
    fontFamily: undefined,
    fontSize: undefined,
    fontWeight: undefined,
  };
  const { fields: frameFields } = useSelector((state) => state.framefield);
  const frameImage = useSelector((state) => state.frame.frameImage);
  const [state, setState] = React.useState(initialValues);
  // const [frameFields, setFields] = React.useState(fields?.filter(f => f.isElection !== true))
  const dispatch = useDispatch();
  const [openDialog, setOpenDailog] = React.useState({
    open: false,
    index: undefined,
  });

  const handleValidation = () => {
    let fields = state;
    let errors = {};
    let formIsValid = true;
    if (!fields["frameName"]) {
      formIsValid = false;
      errors["frameName"] = `Frame Name is required`;
    } else {
      errors["frameName"] = undefined;
    }
    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = `Status is required`;
    } else {
      errors["status"] = undefined;
    }
    if (!fields.regions || fields?.regions?.length <= 0) {
      formIsValid = false;
      errors["region"] = `Region selection is required`;
    } else {
      const checkType = fields?.regions?.find(
        (f) => f.data.dataType === undefined
      );
      if (checkType) {
        formIsValid = false;
        errors["region"] = `Please choose region type`;
      } else {
        errors["region"] = undefined;
      }
    }
    setState({ ...state, errors: errors });
    return formIsValid;
  };

  const handleChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };
  const handleSubmit = () => {
    if (handleValidation()) {
      //   console.log("statesubmit", state, imageRef.current.clientHeight);
      onSubmit({
        ...state,
        imageHeight: imageRef.current.clientHeight,
        imageWidth: imageRef.current.clientWidth,
      });
    }
  };

  const handleClose = () => {
    setState(initialValues);
    // setFields(fields.filter(f => f.isElection === false))
    onClose();
  };

  const handleRegionChange = (regions) => {
    console.log("stateregions", regions);

    setState({
      ...state,
      regions: regions,
    });
  };
  const handleSimpleDialogClose = () => {
    setOpenDailog(false);
    setState({ ...state, index: undefined });
  };

  const handleSimpleDialogOpen = (index) => {
    setOpenDailog({ ...openDialog, open: true, index: index });
  };

  const handleSaveStyle = () => {
    let stateRegions = state.regions;
    stateRegions[openDialog.index].properties = {
      ...stateRegions[openDialog.index].properties,
      fontColor: state.color,
      fontSize: state.fontSize,
      fontWeight: state.fontWeight,
      fontFamily: state.fontFamily,
    };
    // console.log('stateRegions', stateRegions);
    handleRegionChange(stateRegions);

    handleSimpleDialogClose();
  };

  const changeRegionData = (index, event) => {
    const region = state.regions[index];
    const findTypeData = frameFields?.find((f) => f.id === event.target.value);
    if (findTypeData.type === "text") {
      handleSimpleDialogOpen(index);
    }
    handleRegionChange([
      ...state.regions.slice(0, index),
      Object.assign({}, region, {
        data: Object.assign({}, region.data, { dataType: event.target.value }),
        ...findTypeData,
      }),
      ...state.regions.slice(index + 1),
    ]);
  };

  const actionDeleteRegion = (regionIdx) => {
    const filteredRegion = state.regions.filter(
      (reg) => reg.data.index !== regionIdx
    );
    setState({ ...state, regions: filteredRegion });
  };

  const regionRenderer = (regionProps) => {
    return (
      <div>
        <div style={{ position: "absolute", right: 0, top: "-35px" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => actionDeleteRegion(regionProps.data.index)}
          >
            Cancel
          </Button>
        </div>
        <div style={{ position: "absolute", right: 0, bottom: "-45px" }}>
          <TextField
            SelectProps={{
              native: true,
            }}
            select
            size="small"
            variant="outlined"
            style={{ background: "white" }}
            value={regionProps.data.dataType}
            onChange={(event) => {
              changeRegionData(regionProps.index, event);
            }}
          >
            <option value={undefined} selected disabled>
              Choose type
            </option>
            {frameFields?.map((f) => (
              <option value={f.id}>{f.title} ({f.variableName})</option>
            ))}
          </TextField>
        </div>
      </div>
    );
  };

  const onFileChange = (name, files) => {
    if (files.length > 0) dispatch(uploadFrame({ [name]: files }));
  };

  React.useEffect(() => {
    if (Object.keys(data).length != 0) {
      // if (data.isElection) {
      //     const fe = fields.filter(f => f.isElection === true);
      //     setFields(fe)
      // }
      setState({
        ...state,
        frameName: data.frameName,
        status: data.status,
        frameImage: data.path,
        regions: data.regions,
        isElection: data.isElection
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (mode === "Delete") {
      setState(initialValues);
    }
    // if (mode === 'Add') {
    //     const fe = fields.filter(f => f.isElection !== true);
    //     setFields(fe)
    // }
    dispatch(getFrameFields());
  }, [mode]);

  React.useEffect(() => {
    if (frameImage) {
      setState({
        ...state,
        frameImage: frameImage,
      });
    }
  }, [frameImage]);

  const colorValue = state.color ? state.color : "#000";
  return (
    <>
      {openDialog.open && (
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          onClose={handleSimpleDialogClose}
          open={openDialog.open}
        >
          <DialogTitle style={{ padding: "5px 15px" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className={classes.modalTitle}>
                Choose Properties
              </Typography>
              <IconButton aria-label="close" onClick={handleSimpleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Typography>Pick font color</Typography>
                <ColorPicker
                  value={colorValue}
                  defaultValue="#fff"
                  onChange={(color) =>
                    handleChange({ name: "color", value: "#" + color.hex })
                  }
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  select
                  fullWidth
                  name="fontFamily"
                  variant="outlined"
                  size="Normal"
                  label="Font Family"
                  value={state.fontFamily}
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                >
                  {FONTFAMILY.map((font) => {
                    return (
                      <MenuItem
                        value={font.value}
                        style={{ fontFamily: font.style }}
                      >
                        {font.title} (ગુજરાતી)
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item lg={12}>
                <TextField
                  select
                  fullWidth
                  name="fontSize"
                  variant="outlined"
                  size="Normal"
                  label="Font Size"
                  value={state.fontSize}
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                >
                  {FONTSIZES.map((font) => {
                    return <MenuItem value={font.value}>{font.title}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item lg={12}>
                <TextField
                  select
                  fullWidth
                  name="fontWeight"
                  variant="outlined"
                  size="Normal"
                  label="Font Weight"
                  value={state.fontWeight}
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                >
                  {FONTWEIGHTS.map((font) => {
                    return <MenuItem value={font.value}>{font.title}</MenuItem>;
                  })}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: 10 }}>
            <Button
              disabled={isLoading}
              variant="text"
              onClick={() => handleSimpleDialogClose()}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleSaveStyle();
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {open && (
        <Dialog fullScreen onClose={handleClose} fullWidth={true} open={open}>
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar className={classes.toolBar}>
              <Typography variant="h6" className={classes.modalTitle}>
                {`${mode} ${title}`}
              </Typography>
              <IconButton
                edge="start"
                color="secondary"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item lg={6}>
                <Grid container spacing={3}>
                  <Grid item lg={12}>
                    <TextField
                      name="frameName"
                      label="Frame Name"
                      onChange={({ target: { name, value } }) =>
                        handleChange({ name, value })
                      }
                      value={state.frameName}
                      variant="outlined"
                      fullWidth
                      error={state?.errors["frameName"]}
                      helperText={state?.errors["frameName"]}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      select
                      fullWidth
                      name="status"
                      variant="outlined"
                      size="Normal"
                      label="Status"
                      value={state.status}
                      error={state?.errors["status"]}
                      helperText={state?.errors["status"]}
                      onChange={({ target: { name, value } }) =>
                        handleChange({ name, value })
                      }
                    >
                      <MenuItem value="true">Active</MenuItem>
                      <MenuItem value="false">Inactive</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 4,
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: "8.5px 14px",
                      }}
                    >
                      <Typography variant="body1">Is Election</Typography>
                      <Switch
                        color="primary"
                        checked={state.isElection}
                        onChange={({ target: { name, checked } }) => {
                          handleChange({ name: "isElection", value: checked });
                          // if (checked) {
                          //     const fe = fields.filter(f => f.isElection === true);
                          //     setFields(fe)
                          // } else {
                          //     const notfe = fields.filter(f => f.isElection !== true);
                          //     setFields(notfe)
                          // }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12}>
                    <DropzoneArea
                      acceptedFiles={["image/png", "image/jpg", "image/jpeg"]}
                      showFileNames
                      dropzoneText={`Drag and drop a frame here or click *`}
                      showAlerts={false}
                      dropzoneClass={classes.textField}
                      dropzoneParagraphClass={classes.title}
                      maxFileSize={2000000}
                      filesLimit={1}
                      initialFiles={
                        mode == "Edit" ? BASEIMAGEURL + state.frameImage : []
                      }
                      getFileLimitExceedMessage={() => {
                        dispatch(
                          showSnackBar(
                            `Maximum allowed number of files exceeded. Only 1 allowed`,
                            "error"
                          )
                        );
                      }}
                      onChange={(files) => onFileChange("frame", files)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} style={{ marginBottom: 30 }}>
                {state.frameImage && (
                  <RegionSelect
                    regions={state.regions}
                    regionStyle={{ background: "rgba(0, 0, 0, 0.2)" }}
                    constraint
                    onChange={handleRegionChange}
                    regionRenderer={regionRenderer}
                  >
                    <img
                      ref={imageRef}
                      src={BASEIMAGEURL + state.frameImage}
                      width="100%"
                      className={clsx(
                        classes.img,
                        state?.errors["region"] && classes.errorRegion
                      )}
                    />
                  </RegionSelect>
                )}
                {state?.errors["region"] && (
                  <FormHelperText className={classes.error}>
                    {state?.errors["region"]}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ marginTop: 10 }}>
            <Button
              disabled={isLoading}
              variant="text"
              onClick={() => handleClose()}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Loading...&nbsp;&nbsp;
                  <CircularProgress color="inherit" size={15} />
                </>
              ) : mode === "Add" ? (
                "Submit"
              ) : (
                "Save changes"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FrameActionModal;
