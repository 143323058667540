import { utilTypes } from '../types'
const initialstate = {
  spinner: false,
  page: 0,
  rowsPerPage: 10,
  dashboard: {}
};

const utilReducer = (state = initialstate, action) => {
  switch (action.type) {
    case utilTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action?.payload?.data?.data,
      };
    case "SPINNER_START":
      return {
        ...state,
        spinner: true,
      };

    case "SPINNER_STOP":
      return {
        ...state,
        spinner: false,
      };

    case utilTypes.SET_PAGE:
      return {
        ...state,
        page: action?.payload,
      };

    case utilTypes.SET_ROWS_PAGE:
      return {
        ...state,
        rowsPerPage: action?.payload,
      };

    default:
      return state;
  }
};

export default utilReducer;
