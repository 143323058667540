import postApi from "../api/postsApi";
import { postTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllPosts = (cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get posts",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.GET_ALL_POSTS,
      payload: {
        request: {
          url: postApi.GET_ALL_POSTS,
          method: "GET",
        },
      },
    });
};

export const createPost = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'postImages') {
      if (data['postImages'].length > 0) {
        console.log('data[postImages].length', data['postImages'].length);
        data['postImages'].forEach((item, index) => {
          formData.append(`postImages`, data['postImages'][index])
        })
      } else {
        formData.delete('postImages')
      }
    } else {
      formData.append(key, data[key])
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Post created successfully",
      errorMessage: "Failed to create post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.CREATE_POSTS,
      payload: {
        request: {
          url: postApi.CREATE_POSTS,
          method: "POST",
          data: formData
        },
      },
    });
};

export const updatePost = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'postImages') {
      if (data['postImages'].length > 0) {
        console.log('data[postImages].length', data['postImages'].length);
        data['postImages'].forEach((item, index) => {
          formData.append(`postImages`, data['postImages'][index])
        })
      } else {
        formData.delete('postImages')
      }
    } else {
      formData.append(key, data[key])
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Post created successfully",
      errorMessage: "Failed to update post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.UPDATE_POSTS,
      payload: {
        request: {
          url: postApi.UPDATE_POSTS,
          method: "PUT",
          data: formData
        },
      },
    });
};

export const deletePost = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Post deleted successfully",
      errorMessage: "Failed to delete post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.DELETE_POSTS,
      payload: {
        request: {
          url: postApi.DELETE_POSTS,
          method: "DELETE",
          data: data
        },
      },
    });
};

export const getFestivalPost = (cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get festival post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.GET_FESTIVAL_POST,
      payload: {
        request: {
          url: postApi.GET_FESTIVAL_POST,
          method: "GET",
        },
      },
    });
};

export const createFestivalPost = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Festival post created successfully",
      errorMessage: "Failed to create festival post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.CREATE_FESTIVAL_POSTS,
      payload: {
        request: {
          url: postApi.CREATE_FESTIVAL_POSTS,
          method: "POST",
          data: data
        },
      },
    });
};

export const updateFestivalPost = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Festival post updated successfully",
      errorMessage: "Failed to update festival post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.UPDATE_FESTIVAL_POSTS,
      payload: {
        request: {
          url: postApi.UPDATE_FESTIVAL_POSTS,
          method: "PUT",
          data: data
        },
      },
    });
};

export const deleteFestivalPost = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Festival post deleted successfully",
      errorMessage: "Failed to delete festival post",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: postTypes.DELETE_FESTIVAL_POSTS,
      payload: {
        request: {
          url: postApi.DELETE_FESTIVAL_POSTS,
          method: "GET",
          params: { _id: data }
        },
      },
    });
};


