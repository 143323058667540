import { combineReducers } from "redux";
import userReducer from "./userReducer";
import utilReducer from "./utilReducer";
import snackReducer from "./snackReducer";
import allDataReducer from './allDataReducers'
import categoryReducer from './categoryReducer'
import festivalReducer from "./festivalReducer";
import businessReducer from "./businessReducer";
import subcategoryReducer from "./subcategoryReducer";
import postReducer from "./postReducer";
import frameReducer from "./frameReducer"
import frameFieldReducer from "./frameFieldsReducer";
import subscriptionReducer from './subscriptionReducer'
import sliderReducer from "./sliderReducer";
import themeSampleReducer from "./themeSampleReducer"
import greetingReducer from "./greetingReducer";
import loadingReducer from "./loadingReducer";
import customcategoryReducer from "./customcategoryReducer";

export default combineReducers({
  all: allDataReducer,
  user: userReducer,
  business: businessReducer,
  util: utilReducer,
  snack: snackReducer,
  category: categoryReducer,
  festival: festivalReducer,
  subcategory: subcategoryReducer,
  customcategory: customcategoryReducer,
  post: postReducer,
  frame: frameReducer,
  framefield: frameFieldReducer,
  subscription: subscriptionReducer,
  slider: sliderReducer,
  themesample: themeSampleReducer,
  greeting: greetingReducer,
  loading: loadingReducer
});
