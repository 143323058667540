import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import {
  getAllCustomCategories,
  createCustomcategory,
  deleteCustomcategory,
  updateCustomcategory,
  uploadCustomcategoryImages,
  getCustomCateImages,
  sendNotification
} from "../../redux/action/customCategoryActions";
import AddAlertIcon from '@material-ui/icons/AddAlert';

import { useParams } from "react-router";
import CommonAction from "../../components/Common/Actions/CommonAction";
import CommonActionModal from "../../components/Common/Modals/CommonActionModal";
import ImageIcon from "@material-ui/icons/ImageTwoTone";
import { IconButton, Tooltip } from "@material-ui/core";
const Categories = () => {
  const dispatch = useDispatch();
  const { customcategories: data } = useSelector(
    (state) => state.customcategory
  );
  const [uploadOpen, setUploadOpen] = React.useState();
  const [images, setImages] = React.useState([]);


  const handleOpenUpload = async (data) => {
    await dispatch(getCustomCateImages(data.id)).then((res) => {
      if (res.payload.status === 200) {
        setImages(res.payload.data.data);
      }
    });
    setUploadOpen(data);
  };
  const handleCloseUpload = () => {
    setUploadOpen();
    setImages([]);
  };

  const handleUploadSubmit = (data) => {
    dispatch(
      uploadCustomcategoryImages(
        {
          ...data,
          id: uploadOpen.id || uploadOpen._id,
        },
        () => {
          handleCloseUpload();
        }
      )
    );
  };
  const CommonActionBtn = (action) => (
    <CommonAction
      onClick={(row) => handleOpenUpload(action.data)}
      title="Upload Images"
      children={<ImageIcon />}
    />
  );
  const formData = [

    {
      type: "text",
      name: "customcategoryName",
      label: "Custom Category Name",
      placeholder: "Type custom category Name",
      required: true,
      size: 12,
      required: true,
    },
    {
      type: "number",
      name: "priority",
      label: "Priority",
      placeholder: "Type Priority",
      required: true,
      size: 12,
      required: true,
    },
    {
      type: "select",
      name: "status",
      size: 12,

      label: "Status",
      options: [
        {
          title: "Active",
          value: "true",
        },
        {
          title: "Inactive",
          value: "false",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
      required: true,
    },
    // {
    //   type: "file",
    //   name: "subcategoryImage",
    //   label: "Subcategory Image",
    //   placeholder: "Type Subcategory Image",
    //   required: true,
    //   size: 12,
    // },
  ];

  const tableHeaders = [
    // { title: "Image", key: "subcategoryImage", type: "image" },
    { title: "Name", key: "customcategoryName" },
    { title: "Priority", key: "priority" },
    { title: "Status", key: "status" },
  ];

  const handleNotify = (data) => {
    dispatch(sendNotification(data))
  }

  const NotificationAction = (action) => {
    return (
      <Tooltip placement="top" title="Send Notification">
        <IconButton color="primary" onClick={() => handleNotify(action.data)}>
          <AddAlertIcon />
        </IconButton>
      </Tooltip>)
  }

  const pageProps = {
    title: "Custom Categories",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["customcategoryName"],
    searchByLabel: "name",
    modalSize: "xs",
    sortable: true,

    paginated: true,
    tableRowActions: [CommonActionBtn],
    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,

    onAdd: createCustomcategory,
    onEdit: updateCustomcategory,
    onDelete: deleteCustomcategory,

    getData: (e) => getAllCustomCategories(),
  };
  React.useEffect(() => {
    dispatch(getAllCustomCategories());
  }, []);
  const imagesFormdata = [
    {
      type: "file",
      name: "images",
      label: "Image",
      placeholder: "Type Image",
      required: true,
      multiple: true,
      size: 12,
    },
  ];

  return (
    <>
      {imagesFormdata && (
        <CommonActionModal
          formData={imagesFormdata}
          title={"Upload Images"}
          open={Boolean(uploadOpen)}
          onClose={() => handleCloseUpload()}
          mode={"edit"}
          onSubmit={(e) => handleUploadSubmit(e)}
          size={"md"}
          data={{ images: images }}
        // defaultValues={{ images: images }}
        />
      )}

      <PageCreator {...pageProps} />
    </>
  );
};

export default Categories;
