import CategoryIcon from "@material-ui/icons/CategoryTwoTone";
import DashIcon from "@material-ui/icons/DashboardTwoTone";
import PostIcon from "@material-ui/icons/ImageTwoTone";
import UserIcon from "@material-ui/icons/AccountCircleTwoTone";
import FramesIcon from "@material-ui/icons/FilterFramesTwoTone";
import SubCategoryIcon from "@material-ui/icons/RedeemTwoTone";
import SubscriptionsIcon from "@material-ui/icons/SubscriptionsTwoTone";
import SlideIcon from "@material-ui/icons/SlideshowTwoTone";
import GreetIcon from "@material-ui/icons/CakeTwoTone";
import SampleIcon from "@material-ui/icons/BubbleChartTwoTone";

export const superAdminRoutes = [
  {
    type: "heading",
    title: "Master",
  },
  {
    title: "Dashboard",
    icon: <DashIcon />,
    path: "/dashboard",
  },
  {
    title: "App Users",
    icon: <UserIcon />,
    path: "/appusers",
  },
  // {
  //     title: "Subscribed Users",
  //     icon: <UserIcon />,
  //     path: "/subscribedusers",
  // },
  // {
  //   title: "Admin Users",
  //   icon: <UserIcon />,
  //   path: "/adminusers",
  // },
  //   {
  //     title: "Subscriptions",
  //     icon: <SubscriptionsIcon />,
  //     path: "/subscriptions",
  //   },
  {
    type: "heading",
    title: "Content",
  },
  {
    title: "Slider",
    icon: <SlideIcon />,
    path: "/sliders",
  },
  // {
  //   title: "Theme Samples",
  //   icon: <SampleIcon />,
  //   path: "/themesamples",
  // },
  {
    title: "Festivals",
    icon: <SubCategoryIcon />,
    path: "/festivals",
  },
  {
    title: "Greetings",
    icon: <GreetIcon />,
    path: "/greetings",
  },
  {
    title: "Categories",
    icon: <CategoryIcon />,
    path: "/categories",
  },
  // {
  //   title: "Custom Categories",
  //   icon: <CategoryIcon />,
  //   path: "/custom-categories",
  // },
  {
    title: "Posts",
    icon: <PostIcon />,
    path: "/posts",
  },
  // {
  //   title: "Frames",
  //   icon: <FramesIcon />,
  //   path: "/frames",
  // },
  // {
  //     title: "Reports",
  //     icon: <ReportIcon />,
  //     path: "/reports",
  // },
];

export const adminRoutes = [
  {
    type: "heading",
    title: "Master",
  },
  {
    title: "Dashboard",
    icon: <DashIcon />,
    path: "/",
  },
  {
    type: "heading",
    title: "Content",
  },
  {
    title: "Slider",
    icon: <SlideIcon />,
    path: "/sliders",
  },
  // {
  //   title: "Theme Samples",
  //   icon: <SampleIcon />,
  //   path: "/themesamples",
  // },
  {
    title: "Festivals",
    icon: <SubCategoryIcon />,
    path: "/festivals",
  },
  {
    title: "Greetings",
    icon: <GreetIcon />,
    path: "/greetings",
  },
  {
    title: "Categories",
    icon: <CategoryIcon />,
    path: "/categories",
  },
  {
    title: "Custom Categories",
    icon: <CategoryIcon />,
    path: "/custom-categories",
  },
  {
    title: "Posts",
    icon: <PostIcon />,
    path: "/posts",
  },
  {
    title: "Frames",
    icon: <FramesIcon />,
    path: "/frames",
  },
  // {
  //     title: "Reports",
  //     icon: <ReportIcon />,
  //     path: "/reports",
  // },
];

export const employeeRoutes = [
  {
    type: "heading",
    title: "Master",
  },
  {
    title: "Dashboard",
    icon: <DashIcon />,
    path: "/",
  },
  // {
  //     title: "App Users",
  //     icon: <UserIcon />,
  //     path: "/appusers",
  // },
  {
    type: "heading",
    title: "Content",
  },
  {
    title: "Slider",
    icon: <SlideIcon />,
    path: "/sliders",
  },
  {
    title: "Theme Samples",
    icon: <SampleIcon />,
    path: "/themesamples",
  },
  {
    title: "Festivals",
    icon: <SubCategoryIcon />,
    path: "/festivals",
  },
  {
    title: "Greetings",
    icon: <GreetIcon />,
    path: "/greetings",
  },
  {
    title: "Categories",
    icon: <CategoryIcon />,
    path: "/categories",
  },
  {
    title: "Custom Categories",
    icon: <CategoryIcon />,
    path: "/custom-categories",
  },
  {
    title: "Posts",
    icon: <PostIcon />,
    path: "/posts",
  },
  {
    title: "Frames",
    icon: <FramesIcon />,
    path: "/frames",
  },
  // {
  //     title: "Reports",
  //     icon: <ReportIcon />,
  //     path: "/reports",
  // },
];
