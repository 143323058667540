import * as React from 'react';
import { Toolbar, Box, AppBar, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    AppBar: {
        // borderBottom: `1px solid ${theme.palette.gray[1200]}`,
        padding: '10px 0',
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        width: '100%',
        // boxShadow: '0px 2px 7px -1px rgba(0,0,0,0.2)'
    },
    toolBar: {
        display: 'flex',
        width: '80%',
        margin: 'auto',
        justifyContent: 'space-between',
        "& .MuiButtonBase-root": {
            margin: '0 5px'
        }
    },
    title: {
        fontSize: theme.palette.fontSizes.semibase,
        fontWeight: theme.palette.fontWeights.semiBold,
        textTransform: 'capitalize',
        color: theme.palette.gray[600],
    },
    menuPaper: {
        overflow: 'visible',
        boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: theme.palette.radius.medium,
        minWidth: 220,
        width: 'auto'

    },
    menuContainer: {
        padding: '4px 20px 12px 20px',
    },
    btnContainer: {
        padding: '12px 20px 12px 20px',
    },

    avatar: {
        background: theme.palette.primary.main,
        textTransform: 'capitalize'
    }

}))

export default function NavBar({ navlinks }) {
    const classes = useStyles();
    return (
        <Box>
            <AppBar position="static" color="transparent" elevation={0} className={classes.AppBar}>
                <Toolbar className={classes.toolBar}>
                    <Box style={{ borderRadius: 100, width: 'auto', height: 65, }}>
                        <img src="logowhite.png" style={{ width: '100%', height: '100%' }} />
                    </Box>
                    <Box>
                        {
                            navlinks && navlinks?.map(nav => {
                                return (
                                    <Button
                                        size="medium"
                                        variant="text"
                                        aria-label="show 17 new notifications"
                                        onClick={() => window.location.href = nav.link}
                                    >
                                        <Typography className={classes.title} >{nav.title}</Typography>
                                    </Button>

                                )
                            })
                        }


                    </Box>

                </Toolbar>
            </AppBar>
        </Box>
    );
}