import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import { getAllSubscriptions, createSubscription, deleteSubscription, updateSubscription } from "../../redux/action/subscriptionActions";

const Subscription = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.subscription.subscriptions);

  const formData = [
    {
      type: "text",
      name: "subscriptionName",
      label: "Subscription Name",
      placeholder: "Type Subscription Name",
      required: true,
      size: 12,
    },
    {
      type: "number",
      name: "subscriptionAmount",
      label: "Subscription Amount",
      placeholder: "Type Subscription Amount",
      required: true,
      size: 12,
    },
    {
      type: "number",
      name: "subscriptionDuration",
      label: "Subscription Duration",
      placeholder: "Type Subscription Duration",
      required: true,
      size: 12,
    },
    {
      type: "select",
      name: "subscriptionType",
      label: "Subscription Type",
      options: [
        { title: 'Business', value: 'business' },
        { title: 'Panchayat', value: 'panchayat' },
        { title: 'Election', value: 'election' },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
      required: true,
      size: 12,
    },
  ]

  const tableHeaders = [
    { title: "Name", key: "subscriptionName" },
    { title: "Amount", key: "subscriptionAmount" },
    { title: "Duration", key: "subscriptionDuration" },
    { title: "Type", key: "subscriptionType" },
  ];

  const pageProps = {
    title: "Subscription",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["categoryName"],
    searchByLabel: "name",
    modalSize: 'xs',
    sortable: true,

    paginated: true,

    enableImport: false,
    importHeaders: [],

    importData: [],

    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,

    defaultFormValues: {},
    deleteVariableTitle: undefined,
    onAdd: createSubscription,
    onEdit: updateSubscription,
    onDelete: deleteSubscription,
    onImport: () => { },

    getData: (e) => getAllSubscriptions(),
    getImportData: () => { },
    afterAddSuccess: () => { },
    afterEditSuccess: () => { },
    afterDeleteSuccess: () => { },
    afterImportSuccess: () => { },
  };
  React.useEffect(() => {
    dispatch(getAllSubscriptions());
  }, []);
  return (
    <div>
      <PageCreator {...pageProps} />
    </div>
  );
};

export default Subscription;
