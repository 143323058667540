import React from "react";
import Tabbar from "../../components/Tabbar";
import FrameFields from "./FrameFields";
import Frames from "./Frames";

const Frame = () => {
    const tabComp = [
        {
            title: 'Frames',
            component: <Frames />
        },
        {
            title: 'Frame Fields',
            component: <FrameFields />
        },

    ]

    return (
        <Tabbar data={tabComp} />
    );
};

export default Frame;