import frameApi from "../api/frameApi";
import { frameTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllFrames = (cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get frames",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameTypes.GET_ALL_FRAMES,
      payload: {
        request: {
          url: frameApi.GET_ALL_FRAMES,
          method: "GET",
        },
      },
    });
};

export const uploadFrame = (data, cb, errorCb) => {
  console.log("data", data);
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'frame') {
      if (data['frame'].length > 0) {
        data['frame'].forEach((item, index) => {
          formData.append(`frame`, data['frame'][index])
        })
      } else {
        formData.delete('frame')
      }
    } else {
      formData.append(key, data[key])
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame uploaded successfully",
      errorMessage: "Failed to upload frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameTypes.UPLOAD_FRAMES,
      payload: {
        request: {
          url: frameApi.UPLOAD_FRAMES,
          method: "POST",
          data: formData
        },
      },
    });
};

export const createFrame = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame created successfully",
      errorMessage: "Failed to create frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameTypes.CREATE_FRAMES,
      payload: {
        request: {
          url: frameApi.CREATE_FRAMES,
          method: "POST",
          data: data
        },
      },
    });
};

export const updateFrame = (data, cb, errorCb) => {

  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame created successfully",
      errorMessage: "Failed to update frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameTypes.UPDATE_FRAMES,
      payload: {
        request: {
          url: frameApi.UPDATE_FRAMES,
          method: "PUT",
          data: data
        },
      },
    });
};

export const deleteFrame = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Frame deleted successfully",
      errorMessage: "Failed to delete frame",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: frameTypes.DELETE_FRAMES,
      payload: {
        request: {
          url: frameApi.DELETE_FRAMES,
          method: "GET",
          params: { id: data }
        },
      },
    });
};
