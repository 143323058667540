import "./App.css";
import Store from "./redux/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import Routes from "./routes";
import theme from './contants/theme'

function App() {
  return (
    <div className="App">
      <Provider store={Store()}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
