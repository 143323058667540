import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  title: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  header: {
    margin: theme.spacing(5, 0, 2, 0),
    fontWeight: theme.palette.fontWeights.semiBold,
  },
}));

const Privacy = () => {
  const classes = useStyles();
  const [sticky, setSticky] = React.useState();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Privacy Policy for Panchayat Post
      </Typography>

      <Typography className={classes.text}>
        At Panchayat Post, one of our main priorities is the privacy of our
        visitors. This Privacy Policy document contains types of information
        that is collected and recorded by Panchayat Post and how we use it.
      </Typography>
      <Typography className={classes.text}>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </Typography>
      <Typography className={classes.header} variant="h5">
        Privacy Policies
      </Typography>
      <Typography className={classes.text}>
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.
      </Typography>
      <Typography className={classes.text}>
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </Typography>
      <Typography className={classes.header} variant="h5">
        Third Party Privacy Policies
      </Typography>
      <Typography className={classes.text}>
        Panchayat Post's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.
      </Typography>

      <Typography className={classes.header} variant="h5">
        Data Safety
      </Typography>
      <Typography className={classes.text}>
        We store your mobile number, name, and basic address details for your
        login and further use of the app. We do not share or sell data to
        anyone.
      </Typography>
      <Typography className={classes.header} variant="h5">
        Children's Information
      </Typography>
      <Typography className={classes.text}>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </Typography>
      <Typography className={classes.text}>
        Panchayat Post does not knowingly collect any Personal Identifable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our App, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </Typography>

      <Typography className={classes.header} variant="h5">
        Online Privacy Policy Only
      </Typography>
      <Typography className={classes.text}>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our App with regards to the information that they shared
        and/or collect in Panchayat Post. This policy is not applicable to any
        information collected offine or via channels other than this app.
      </Typography>
      <Typography className={classes.header} variant="h5">
        Payment
      </Typography>
      <Typography className={classes.text}>
        We offer paid features in app, any payment done by you for a paid
        features will be non-refundable in any case.
      </Typography>

      <Typography className={classes.header} variant="h5">
        Consent
      </Typography>
      <Typography className={classes.text}>
        By using our app, you hereby consent to our Privacy Policy and agree to
        its Terms and Conditions.
      </Typography>
    </div>
  );
};
export default Privacy;
