const postApi = {
  GET_ALL_POSTS: `/post`,
  CREATE_POSTS: '/post/create',
  UPDATE_POSTS: '/post/update',
  DELETE_POSTS: '/post/delete',
  GET_FESTIVAL_POST: '/post/festivalpost',
  CREATE_FESTIVAL_POSTS: '/post/createfestivalpost',
  UPDATE_FESTIVAL_POSTS: '/post/updatefestivalpost',
  DELETE_FESTIVAL_POSTS: '/post/deletefestivalpost',
};
export default postApi;
