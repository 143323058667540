import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "./Navbar";
import { Button, Grid, Typography } from "@material-ui/core";
import InfoCard from "../../components/InfoCard";
import PhoneIcon from "@material-ui/icons/PhoneIphoneTwoTone";
import EmailIcon from "@material-ui/icons/EmailTwoTone";
import LocationIcon from "@material-ui/icons/LocationOnTwoTone";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  bg: {
    "&:before": {
      background: "rgba(32,32,32,0.2)",
    },
  },
  bgImage: {
    width: "100%",
    height: "100%",
    "& img": {
      left: "0px",
      top: "0px",
      overflow: "hidden",
      margin: "0px",
      padding: "0px",
      zIndex: "-999999",
      position: "absolute",
      width: "100%",
      objectFit: "cover",
      height: "100%",
    },
  },
  bannerContainer: {
    padding: theme.spacing(0, 20, 12, 20),
    "& img": {
      width: "85%",
      objectFit: "cover",
      height: "100%",
    },
  },
  bannerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    color: theme.palette.gray[600],
    marginBottom: theme.spacing(5),
  },
  subtitle: {
    fontWeight: theme.palette.fontWeights.regular,
    textTransform: "capitalize",
    color: theme.palette.gray[700],
  },
  headerContainer: {
    textAlign: "center",
    padding: theme.spacing(5, 0),
  },
  headerTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    fontWeight: theme.palette.fontWeights.bold,
    marginBottom: theme.spacing(3),
  },
  headerSubTitle: {
    margin: "auto",
    width: "50%",
    color: theme.palette.gray[1200],
    fontWeight: theme.palette.fontWeights.regular,
  },
  contactContainer: {
    borderTop: `1px solid ${theme.palette.gray[500]}`,
    width: "100%",
    padding: theme.spacing(10, 0),
    // backgroundColor: "rgba(0, 171, 85,0.8)",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(5, 0),
    backgroundColor: "#001027",
  },
  navList: {
    display: "flex",
    listStyleType: "none",
    alignItems: "center",
    flex: 1.5,
    paddingTop: theme.spacing(2),
  },
  navLink: {
    textTransform: "uppercase",
    color: theme.palette.gray[600],
    fontSize: theme.palette.fontSizes.semibase,
    fontWeight: theme.palette.fontWeights.regular,
  },
  count: {
    textAlign: "center",
    fontSize: theme.palette.fontSizes.xl,
    color: theme.palette.text.primary,
    fontWeight: theme.palette.fontWeights.bold,
    marginTop: theme.spacing(1),
  },
}));

const Home = () => {
  const classes = useStyles();
  const navlinks = [
    {
      title: "Home",
      link: "#home",
    },
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Contact",
      link: "#contact",
    },
  ];
  const history = useHistory();
  return (
    <>
      <NavBar navlinks={navlinks} id="home" />
      <div className={classes.root}>
        <div className={classes.bg}>
          <Grid container className={classes.bannerContainer}>
            <Grid item lg={6} className={classes.bannerContent}>
              <Typography variant="h3" className={classes.title}>
                Promote your Business with Creative Images{" "}
              </Typography>
              <Typography variant="h6" className={classes.subtitle}>
                Best Digital Marketing app to promote your business for 365
                Days. Save and share images with your business logo & company
                details.
              </Typography>
            </Grid>
            <Grid item lg={6}>
              <img src="images/mockup.png" />
            </Grid>
          </Grid>
          <div className={classes.bgImage}>
            <img src="images/bg1.jpg" />
          </div>
        </div>
        <div className={classes.headerContainer} id="about">
          <Typography variant="h3" className={classes.headerTitle}>
            About Panchayat Post
          </Typography>
          <Typography variant="body1" className={classes.headerSubTitle}>
            to automatically create Festival Posts and various other types of
            posts to share and download for various purposes
          </Typography>
          <Grid
            container
            className={classes.bannerContainer}
            style={{ paddingBottom: 20 }}
          >
            <Grid item lg={6}>
              <img src="images/about.png" />
            </Grid>
            <Grid item lg={6} className={classes.bannerContent}>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Panchayat Post is Indian and Gujarat Festival Poster Maker App
                helps Gram Panchayat, Sarpanch, Talati, VCE, Panchayat Sadasya,
                Business owner and Panchayat Election Campaign to Designs Daily
                Post, Festival Post, Sarkari Yojana, Suvichar, Election
                Publicity, for Digital Marketing to Promote. Panchayat Post
                Maker App in languages like Gujarati, Hindi, English etc.
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.contactContainer} id="contact">
          <Typography variant="h3" className={classes.headerTitle}>
            Get In Touch
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 30,
            }}
          >
            {/* <InfoCard
              style={{ margin: 10 }}
              title={"Phone Number"}
              icon={PhoneIcon}
              count={"+91 97231 65365"}
            /> */}
            <InfoCard
              style={{ margin: 10 }}
              title={"Email Address"}
              icon={EmailIcon}
              count="panchayatpost108@gmail.com"
            />
            <InfoCard
              style={{ margin: 10 }}
              title={"Address"}
              icon={LocationIcon}
              count={
                <>
                  <Typography className={classes.count}>
                    Info City, Gandhinagar
                  </Typography>
                  <Typography className={classes.count}>Gujarat</Typography>
                </>
              }
            />
          </div>
        </div>
        <div className={classes.footer}>
          <div style={{ borderRadius: 100, width: 200, height: 65 }}>
            <img
              src="logowhite.png"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <ul className={classes.navList}>
            <li>
              <Button
                size="large"
                variant="text"
                onClick={() => history.push("/terms-condition")}
              >
                <Typography className={classes.navLink}>
                  Terms & condition
                </Typography>
              </Button>
            </li>
            <li>
              <Button
                size="large"
                variant="text"
                onClick={() => history.push("/privacy-policy")}
              >
                <Typography className={classes.navLink}>
                  Privacy Policy
                </Typography>
              </Button>
            </li>
            <li>
              <Button
                size="large"
                variant="text"
                onClick={() => history.push("/refund-cancellation")}
              >
                <Typography className={classes.navLink}>
                  Refund & Cancellation
                </Typography>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Home;
