import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ScheduleCalendar from "../../components/Calendar";
import FestivalActionModal from "../../components/Common/Modals/FestivalActionModal";
import moment from "moment";
import {
  createFestivalPost,
  deleteFestivalPost,
  getFestivalPost,
  updateFestivalPost,
} from "../../redux/action/postsActions";
import DeleteModal from "../../components/Common/Modals/CommonDeleteModal";
import { getAllFestivals } from "../../redux/action/festivalActions";
import loadingSelector from "../../redux/selectors/loadingSelector";
import { postTypes } from "../../redux/types";

const FestivalPosts = () => {
  const dispatch = useDispatch();
  const [actionData, setActionData] = React.useState();
  const [open, setOpen] = React.useState();
  const { festivalPost: events, festivals } = useSelector(
    (state) => state.post
  );

  const toggleAdd = (mode) => {
    setOpen(mode);
    if (mode === undefined) {
      setActionData({});
    }
  };

  const handleClick = (data) => {
    toggleAdd("Add");
    setActionData({
      ...data,
      start: moment(data.start).toDate(),
      end: moment(data.end).toDate(),
    });
  };

  const handleEventClick = (data) => {
    toggleAdd("Edit");
    setActionData({
      ...data,
      start: moment(data.start).toDate(),
      end: moment(data.end).toDate(),
    });
  };

  const handleEventDelete = (data) => {
    toggleAdd("Delete");
    setActionData({
      ...data,
    });
  };

  const onAdd = (data) => {
    if (open === "Add") {
      dispatch(
        createFestivalPost(
          data,
          () => {
            toggleAdd();
            dispatch(getFestivalPost());
          },
          []
        )
      );
    }
    if (open === "Edit") {
      let editData = {
        ...actionData,
        ...data,
      };
      dispatch(
        updateFestivalPost(editData, () => {
          toggleAdd();
          dispatch(getFestivalPost());
        })
      );
    }
    if (open === "Delete") {
      dispatch(
        deleteFestivalPost(actionData._id || actionData.id, () => {
          toggleAdd();
          dispatch(getFestivalPost());
        })
      );
    }
  };

  React.useEffect(() => {
    dispatch(getFestivalPost());
    dispatch(getAllFestivals());
  }, []);

  const isPostLoading = useSelector((state) =>
    loadingSelector(postTypes.CREATE_FESTIVAL_POSTS, state)
  );
  return (
    <>
      <FestivalActionModal
        title={"Festival Post"}
        open={open === "Add" || open === "Edit"}
        onClose={() => toggleAdd()}
        mode={open}
        data={actionData}
        festivals={festivals}
        onSubmit={(e) => onAdd(e)}
        onDelete={(e) => handleEventDelete(e)}
        size="md"
        isLoading={isPostLoading}
      />
      <DeleteModal
        size="md"
        open={open === "Delete"}
        onClose={() => toggleAdd()}
        onConfirm={(e) => onAdd(e)}
      />
      <ScheduleCalendar
        events={events}
        handleSelect={(data) => handleClick(data)}
        handleEventSelect={(data) => handleEventClick(data)}
      />
    </>
  );
};

export default FestivalPosts;
