import festivalApi from "../api/festivalApi";
import { festivalTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllFestivals = (cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get festivals",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: festivalTypes.GET_ALL_FESTIVALS,
      payload: {
        request: {
          url: festivalApi.GET_ALL_FESTIVALS,
          method: "GET",
        },
      },
    });
};

export const createFestival = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'festivalImages') {
      if (data['festivalImages'].length > 0) {
        data['festivalImages'].forEach((item, index) => {
          formData.append(`festivalImages`, data['festivalImages'][index])
        })
      } else {
        formData.delete('festivalImages')
      }
    } else {
      if (data[key]) {
        formData.append(key, data[key])
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Festival created successfully",
      errorMessage: "Failed to create festival",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: festivalTypes.CREATE_FESTIVALS,
      payload: {
        request: {
          url: festivalApi.CREATE_FESTIVALS,
          method: "POST",
          data: formData
        },
      },
    });
};

export const updateFestival = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'festivalImages') {
      if (data['festivalImages'].length > 0) {
        console.log('data[festivalImages].length', data['festivalImages'].length);
        data['festivalImages'].forEach((item, index) => {
          formData.append(`festivalImages`, data['festivalImages'][index])
        })
      } else {
        formData.delete('festivalImages')
      }
    } else {
      if (data[key]) {
        formData.append(key, data[key])
      }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Festival created successfully",
      errorMessage: "Failed to update festival",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: festivalTypes.UPDATE_FESTIVALS,
      payload: {
        request: {
          url: festivalApi.UPDATE_FESTIVALS,
          method: "PUT",
          data: formData
        },
      },
    });
};

export const deleteFestival = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Festival deleted successfully",
      errorMessage: "Failed to delete festival",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: festivalTypes.DELETE_FESTIVALS,
      payload: {
        request: {
          url: festivalApi.DELETE_FESTIVALS,
          method: "DELETE",
          data: data
        },
      },
    });
};


export const sendNotification = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Notification sent Successfully",
      errorMessage: "Failed to send notification",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: festivalTypes.SEND_NOTIFICATION,
      payload: {
        request: {
          url: festivalApi.SEND_NOTIFICATION,
          method: "post",
          data: data,
        },
      },
    });
};
