import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import NavBar from "../home/Navbar";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { deleteUserAccount } from "../../redux/action/userActions";
import useResponsive from "../../helper/useResponsive";
import { mobileRegex } from "../../helper/regex";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  header: {
    margin: theme.spacing(5, 0, 2, 0),
    fontWeight: theme.palette.fontWeights.semiBold,
  },
}));

const AccountDelete = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobile: "",
      pin: "",
    },
  });

  const onSubmit = (data) => {
    dispatch(
      deleteUserAccount(
        data,
        (res) => {
          setAlert({
            severtiy: "success",
            message: "Your account has been deleted",
          });
        },
        (err) => {
          setAlert({
            severtiy: "error",
            message: "Failed to delete your account",
          });
        }
      )
    );
  };
  //   const [sticky, setSticky] = React.useState();
  const isMobile = useResponsive("down", "sm");
  const btnRef = useRef();
  console.log("errors", errors);

  return (
    <div className={classes.root}>
      <NavBar />
      {/* <Typography className={classes.title} variant="h4">
        Delete Your Account
      </Typography> */}
      <div className={classes.container}>
        <Card style={{ width: isMobile ? "80vw" : "30vw" }}>
          <CardHeader title={"Delete Your Account"} />
          <Divider />
          {alert?.severity === "success" && (
            <>
              <CardContent>
                <Alert severity={alert.severity} title={alert.message} />
              </CardContent>

              <Divider />
            </>
          )}

          {alert?.severity !== "success" && (
            <>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    style={{
                      display: "grid",
                      gridTemplateRows: "1fr",
                      gap: 10,
                    }}
                  >
                    <Controller
                      control={control}
                      name="mobile"
                      rules={{
                        required: {
                          value: true,
                          message: "Mobile Number is required",
                        },
                        pattern: {
                          value: mobileRegex,
                          message: "Invalid mobile number",
                        },
                      }}
                      render={(field) => {
                        return (
                          <TextField
                            label="Mobile Number"
                            // {...register("mobile", { required: true })}
                            {...field}
                            variant="outlined"
                            {...(errors["mobile"] && {
                              helperText: errors["mobile"].message,
                              error: true,
                            })}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="pin"
                      rules={{
                        required: true,
                      }}
                      render={(field) => {
                        return (
                          <TextField
                            label="Pin"
                            {...field}
                            variant="outlined"
                            {...(errors["pin"] && {
                              helperText: "Pin Number is required",
                              error: true,
                            })}
                          />
                        );
                      }}
                    />

                    {/* <TextField
                  label="Pin"
                  {...register("pin", { required: true })}
                  variant="outlined"
                  {...(errors["pin"] && {
                    helperText: "Pin Number is required",
                    error: true,
                  })}
                /> */}
                    <input type="submit" hidden={true} ref={btnRef} />
                  </Box>
                </form>
              </CardContent>
              <Divider />
              <CardContent>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => btnRef.current.click()}
                >
                  Delete Account
                </Button>
              </CardContent>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};
export default AccountDelete;
