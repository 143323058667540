import sliderApi from "../api/sliderApi";
import { sliderTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllSliders = (status, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get categories",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: sliderTypes.GET_ALL_SLIDERS,
      payload: {
        request: {
          url: sliderApi.GET_ALL_SLIDERS,
          method: "GET",
          params: {
            status: status,
          }
        },
      },
    });
};

export const createSlider = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    formData.append(key, data[key])
    if (key === 'sliderImage') {
      if (Array.isArray(data.sliderImage)) { formData.append(key, data.sliderImage[0]) }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Slider created successfully",
      errorMessage: "Failed to create category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: sliderTypes.CREATE_SLIDERS,
      payload: {
        request: {
          url: sliderApi.CREATE_SLIDERS,
          method: "POST",
          data: formData
        },
      },
    });
};

export const updateSlider = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    formData.append(key, data[key])
    if (key === 'sliderImage') {
      if (Array.isArray(data.sliderImage)) { formData.append(key, data.sliderImage[0]) }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Slider created successfully",
      errorMessage: "Failed to update category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: sliderTypes.UPDATE_SLIDERS,
      payload: {
        request: {
          url: sliderApi.UPDATE_SLIDERS,
          method: "PUT",
          data: formData
        },
      },
    });
};

export const deleteSlider = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Slider deleted successfully",
      errorMessage: "Failed to delete category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: sliderTypes.DELETE_SLIDERS,
      payload: {
        request: {
          url: sliderApi.DELETE_SLIDERS,
          method: "DELETE",
          data: data
        },
      },
    });
};
