import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
}));

const Restaurants = () => {
  const classes = useStyles();

  return <div className={classes.root}>restaurants</div>;
};
export default Restaurants;
