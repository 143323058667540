const initialstate = {
  users: [],
  categories: [],
};

const allDataReducer = (state = initialstate, action) => {
  switch (action.type) {



    default:
      return state;
  }
};

export default allDataReducer;
