import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { hideSnackBar } from "../../../redux/action/snackActions";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  alert: {
    borderRadius: theme.palette.radius.base
  }
}));

const SnackComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const snack = useSelector((state) => state.snack);
  // React.useEffect(() => {
  //   if (snack.open) {
  //     setTimeout(() => {
  //       dispatch(hideSnackBar());
  //     }, 4000);
  //   }
  // }, [snack.open]);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={4000}
      onClose={() => dispatch(hideSnackBar())}
      open={Boolean(snack.open)}
    >
      {snack.message ? <Alert
        variant="filled"
        onClose={() => dispatch(hideSnackBar())}
        severity={snack.severity}
        className={classes.alert}
      >
        {snack.message}
      </Alert> : undefined}
    </Snackbar>
  );
};
export default SnackComponent;
