import CustomcategoryApi from "../api/customcategoryApi";
import { customcategoryTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllCustomCategories = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get subcategories",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: customcategoryTypes.GET_ALL_CUSTOMCATEGORIES,
      payload: {
        request: {
          url: CustomcategoryApi.GET_ALL_CUSTOMCATEGORIES,
          method: "GET",
        },
      },
    });
};

export const createCustomcategory = (data, cb, errorCb) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key) => {
  //   if (key === 'CustomcategoryImage') {
  //     if (data['CustomcategoryImage'].length > 0) {
  //       console.log('data[CustomcategoryImage].length', data['CustomcategoryImage'].length);
  //       data['CustomcategoryImage'].forEach((item, index) => {
  //         formData.append(`CustomcategoryImage`, data['CustomcategoryImage'][index])
  //       })
  //     } else {
  //       formData.delete('CustomcategoryImage')
  //     }
  //   } else {
  //     formData.append(key, data[key])
  //   }

  // });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Customcategory created successfully",
      errorMessage: "Failed to create Customcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: customcategoryTypes.CREATE_CUSTOMCATEGORIES,
      payload: {
        request: {
          url: CustomcategoryApi.CREATE_CUSTOMCATEGORIES,
          method: "POST",
          data: data
        },
      },
    });
};

export const updateCustomcategory = (data, cb, errorCb) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key, i) => {
  //   formData.append(key, data[key])
  //   if (key === 'CustomcategoryImage') {
  //     if (Array.isArray(data.CustomcategoryImage)) { formData.append(key, data.CustomcategoryImage[0]) }
  //   }
  // });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Customcategory created successfully",
      errorMessage: "Failed to update Customcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: customcategoryTypes.UPDATE_CUSTOMCATEGORIES,
      payload: {
        request: {
          url: CustomcategoryApi.UPDATE_CUSTOMCATEGORIES,
          method: "PUT",
          data: data
        },
      },
    });
};

export const getCustomCateImages = (data, cb, errorCb) => {
  return {
    type: customcategoryTypes.GET_CUSTOMCATEGOEY_IMAGES,
    payload: {
      request: {
        url: CustomcategoryApi.GET_CUSTOMCATEGOEY_IMAGES,
        method: "GET",
        params: { id: data }
      },
    },
  };
}


export const uploadCustomcategoryImages = (data, cb, errorCb) => {
  const formData = new FormData();

  data.images.forEach((k) => {
    formData.append('images', k)
  })
  formData.append('id', data.id)
  formData.append('categoryId', data.categoryId)


  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Customcategory images added  successfully",
      errorMessage: "Failed to add Customcategory images",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: customcategoryTypes.UPLOAD_CUSTOMCATEGORY_IMAGES,
      payload: {
        request: {
          url: CustomcategoryApi.UPLOAD_CUSTOMCATEGORY_IMAGES,
          method: "POST",
          data: formData
        },
      },
    });
};

export const deleteCustomcategory = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Customcategory deleted successfully",
      errorMessage: "Failed to delete Customcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: customcategoryTypes.DELETE_CUSTOMCATEGORIES,
      payload: {
        request: {
          url: CustomcategoryApi.DELETE_CUSTOMCATEGORIES,
          method: "DELETE",
          data: data
        },
      },
    });
};

export const sendNotification = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Notification sent Successfully",
      errorMessage: "Failed to send notification",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: customcategoryTypes.SEND_NOTIFICATION,
      payload: {
        request: {
          url: CustomcategoryApi.SEND_NOTIFICATION,
          method: "post",
          data: data,
        },
      },
    });
};
