import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/DeleteTwoTone'
import React from "react";

const DeleteCommonAction = ({ onClick }) => {
  return (
    <Tooltip arrow placement="top" title="Delete">
      <IconButton aria-label="delete" onClick={() => onClick()}>
        <DeleteIcon fontSize="small" color="error" />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteCommonAction;
