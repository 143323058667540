import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  title: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  header: {
    margin: theme.spacing(5, 0, 2, 0),
    fontWeight: theme.palette.fontWeights.semiBold,
  },
}));

const Refund = () => {
  const classes = useStyles();
  const [sticky, setSticky] = React.useState();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Refund & Cancellation Policy
      </Typography>

      <Typography className={classes.text}>
        If at any time you are not satisfed with the quality of services of our
        application, you may raise a ticket in our application's customer help
        and support with a reason for dissatisfaction or call customer care
        number of Panchayat Post . If your issue is found to be genuine, your
        subscription may be considered for a claim of credit, replacement or
        refund. Otherwise, your complaint will be noted and considered for the
        respective resolution. Furthermore, you hereby confrm and agree that you
        will not claim or allege anything against Panchayat Post concerning the
        digital content provided to you by the app.
      </Typography>
    </div>
  );
};
export default Refund;
