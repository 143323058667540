import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import { getAllThemeSample, createThemeSample, deleteThemeSample, updateThemeSample } from "../../redux/action/themeSampleActions";

const ThemeSample = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.themesample.themeSamples);


  const formData = [
    {
      type: "file",
      name: "themeSampleImage",
      label: "Theme Sample Image",
      placeholder: "Type Theme Sample Image",
      required: true,
      size: 12,
    },
  ]

  const tableHeaders = [
    { title: "Image", key: "themeSampleImage", type: 'image' },
  ];

  const pageProps = {
    title: "Theme Sample",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: [""],
    searchByLabel: "",
    modalSize: 'sm',
    sortable: true,

    paginated: true,

    enableImport: false,
    importHeaders: [],

    importData: [],

    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    pageHeaderActions: [],

    defaultFormValues: {},
    deleteVariableTitle: undefined,
    onAdd: createThemeSample,
    onEdit: updateThemeSample,
    onDelete: deleteThemeSample,
    onImport: () => { },

    getData: (e) => getAllThemeSample(),
    getImportData: () => { },
    afterAddSuccess: () => { },
    afterEditSuccess: () => { },
    afterDeleteSuccess: () => { },
    afterImportSuccess: () => { },
  };
  React.useEffect(() => {
    dispatch(getAllThemeSample());
  }, []);
  return (
    <div>
      <PageCreator {...pageProps} />
    </div>
  );
};

export default ThemeSample;
