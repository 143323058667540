import { categoryTypes } from "../types";

const initialstate = {
  categories: [],
};

const categoryReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case categoryTypes.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: getData()?.data,
      };
    default:
      return state;
  }
};

export default categoryReducer;
