import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Controller } from "react-hook-form";
import InputContainer from "./InputContainer";
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  textField: {
    borderRadius: theme.palette.radius.base,
  },
}))
const MyTextField = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    label,
    name,
    options,
    optionLabelProp,
    optionValueProp,
    placeholder,
    multiline,
    rows,
    control,
    error,
    defaultOption,
    size,
    noPadding,
    disabled,
    required,
    rules,
    hasDefaultOption = false,
    onSelect
  } = props;
  return (
    <InputContainer
      size={size}
    >
      <Controller
        control={control}
        name={name}
        {...props}
        render={(props) => {
          return (

            <Autocomplete
              {...props}
              multiple
              fullWidth
              options={options}
              onChange={(e, v) => { props.onChange(v); }}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label={label}
                  placeholder={placeholder}
                />
              )}
            />

          )
        }} // props contain
        rules={rules}
      />

    </InputContainer>
  );
});
export default MyTextField;
