import { greetingTypes } from "../types";

const initialstate = {
  greetings: [],
  greetImages: []
};

const greetingReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case greetingTypes.GET_ALL_GREETINGS_SUCCESS:
      return {
        ...state,
        greetings: getData()?.data,
      };

    case greetingTypes.GET_GREETING_IMAGES_SUCCESS:
      return {
        ...state,
        greetImages: getData()?.data,
      };
    default:
      return state;
  }
};

export default greetingReducer;
