const userApi = {
  GET_APP_USERS: `/users/appusers`,
  GET_APP_USER_INFO: `/users/appuserinfo`,

  GET_ADMIN_USERS: `/users/adminusers`,
  GET_SUBSCRIBED_USERS: `/users/subscribedusers`,
  CREATE_USER: "/users/create",
  UPDATE_USER: "/users/update",
  DELETE_USER: "/users/delete",
  ASSIGN_SUBSCRIPTION: "/users/assignsubscription",
  DELETE_SUBSCRIPTION: "/users/deletesubscription",
  ADD_BUSINESS: "/users/addbusiness",
  ADD_GRAMPANCHAYAT: "/users/addgrampanchayat",
  ADD_ELECTION: "/users/addelection",
};
export default userApi;
