import { Box, Button, ButtonGroup, IconButton, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import MonthIcon from '@material-ui/icons/GridOnTwoTone';
import WeekIcon from '@material-ui/icons/ViewWeekTwoTone';
import DayIcon from '@material-ui/icons/ViewDayTwoTone';
import AgendaIcon from '@material-ui/icons/ViewAgendaTwoTone';

function RBCToolbar(props) {
    const viewState = props.view
    const handleView = (viewType) => {
        props.onView(viewType)
    }
    const today = moment(props.date).isSame(moment(new Date()).startOf('day').toDate());
    const getCustomToolbar = () => {
        // const toolbarDate = props.date;

        const goToBack = () => {
            let view = viewState;
            let mDate = props.date;
            let newDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
            } else if (view === "week") {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() - 7,
                    1
                );
            } else {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() - 1,
                    1
                );
            }
            props.onNavigate("prev", newDate);
        };
        const goToNext = () => {
            let view = viewState;
            let mDate = props.date;
            let newDate;
            if (view === "month") {
                newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
            } else if (view === "week") {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 7,
                    1
                );
            } else {
                newDate = new Date(
                    mDate.getFullYear(),
                    mDate.getMonth(),
                    mDate.getDate() + 1,
                    1
                );
            }
            props.onNavigate("next", newDate);
        };

        const goToToday = () => {
            const now = new Date();
            props.date.setMonth(now.getMonth());
            props.date.setYear(now.getFullYear());
            props.date.setDate(now.getDate());
            props.onNavigate("current");
        };

        const goToBackYear = () => {
            let mDate = props.date;
            let newDate = new Date(mDate.getFullYear() - 1, 1);
            props.onNavigate("prev", newDate);
        };

        const goToNextYear = () => {
            let mDate = props.date;
            let newDate = new Date(mDate.getFullYear() + 1, 1);
            props.onNavigate("next", newDate);
        };

        const month = () => {
            const date = moment(props.date);
            let month = date.format("MMMM");
            let day = date.format("D");
            let year = date.format("Y");

            return (
                <Typography variant="h5" style={{ fontWeight: 600 }}>{`${day} ${month} ${year}`}</Typography>
            );
        };
        const getIcons = (view) => {
            switch (view) {
                case 'month':
                    return (
                        <MonthIcon />
                    )
                case 'week':
                    return (
                        <WeekIcon />
                    )
                case 'day':
                    return (
                        <DayIcon />
                    )
                case 'agenda':
                    return (
                        <AgendaIcon />
                    )
                default:
                    break;
            }
        }


        return (
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <IconButton color="primary" onClick={goToBack}><LeftArrowIcon /></IconButton>
                    <Button onClick={goToToday} color="primary" variant={today ? 'contained' : 'outlined'}>Today</Button>
                    <IconButton color="primary" onClick={goToNext}><RightArrowIcon /></IconButton>
                </Box>
                {month()}
                <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {
                        props.views.map((view) => {
                            return (
                                <Tooltip title={view.toUpperCase()}>
                                    <IconButton onClick={() => handleView(view)} color={viewState === view ? 'primary' : 'secondary'}>
                                        {getIcons(view)}
                                    </IconButton>
                                </Tooltip>
                            )
                        })
                    }

                </Box>
            </Box >
        );
    };

    return <>{getCustomToolbar()}</>;
}

export default RBCToolbar;