import { Badge, IconButton, Tooltip } from "@material-ui/core";
import React from "react";

const CommonAction = (props) => {
  const { onClick, children, title } = props;
  return (
    <Tooltip arrow placement="top" title={title || "title"}>
      <IconButton {...props} title={undefined} onClick={() => onClick()}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default CommonAction;
