import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Modal } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white
  },
}));

const SpinnerModal = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true} >
      <CircularProgress color="inherit" size={50} />
    </Backdrop>
  );
};
export default SpinnerModal;
