import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import { getAllBusinesses, createBusiness, deleteBusiness, updateBusiness } from "../../redux/action/businessActions";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import { useParams } from "react-router";

const Categories = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.business.businesses);
  const { id } = useParams()
  const formData = [
    {
      type: "text",
      name: "businessName",
      label: "Business Name",
      placeholder: "Type Business Name",
      required: true,
      size: 12,
      required: true,
    },

    {
      type: "email",
      name: "businessEmail",
      label: "Business Email",
      placeholder: "Type Business Email",
      required: true,
      size: 4,
    },
    {
      type: "mobile",
      name: "businessMobile",
      label: "Business Mobile",
      placeholder: "Type Business Mobile",
      size: 4,
      required: true,
    },
    {
      type: "url",
      name: "businessWebsite",
      label: "Business Website",
      placeholder: "Type Business Website",
      required: true,
      size: 4,
    },
    {
      type: "text",
      name: "businessTagLine",
      label: "Business Tag Line",
      placeholder: "Type Business Tag Line",
      required: true,
      size: 12,
      required: true,
    },
    {
      type: "textarea",
      name: "businessAddress",
      label: "Business Address",
      placeholder: "Type Business Address",
      required: true,
      size: 12,
    },
    {
      type: "file",
      name: "businessLogo",
      label: "Business Logo",
      placeholder: "Type Business Logo",
      required: true,
      size: 12,
    },
    {
      type: "file",
      name: "businessFrame",
      label: "Frames",
      required: true,
      size: 12,
      multiple: true
    },
  ]

  const tableHeaders = [
    { title: "Logo", key: "businessLogo", type: 'image' },
    { title: "Name", key: "businessName" },
    { title: "Email", key: "businessEmail" },
    { title: "Mobile", key: "businessMobile" },
    { title: "Address", key: "businessAddress" },
  ];

  const pageProps = {
    title: "Businesses",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["businessName"],
    searchByLabel: "name",
    modalSize: 'md',
    sortable: true,

    paginated: true,

    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,

    onAdd: (data, cb, errorCb) => createBusiness({ ...data, userId: id, }, cb, errorCb),
    onEdit: updateBusiness,
    onDelete: deleteBusiness,

    getData: (e) => getAllBusinesses(id),

  };
  React.useEffect(() => {
    dispatch(getAllBusinesses(id));
  }, [id]);
  return (
    <div>
      <PageCreator {...pageProps} />
    </div>
  );
};

export default Categories;
