const subcategoryApi = {
  GET_ALL_SUBCATEGORIES: `/subcategories`,
  CREATE_SUBCATEGORIES: '/subcategories/create',
  UPDATE_SUBCATEGORIES: '/subcategories/update',
  GET_SUBCATEGOEY_IMAGES: '/subcategories/getimages',
  UPLOAD_SUBCATEGORY_IMAGES: '/subcategories/uploadimages',
  DELETE_SUBCATEGORIES: '/subcategories/delete',
  SEND_NOTIFICATION: '/subcategories/notify'
};
export default subcategoryApi;
