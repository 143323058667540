import { userTypes } from "../types";

const initialstate = {
  isLogged: false,
  getDetailsLoading: false,
  token: undefined,
  businesses: [],
  closeModal: false,
};

const businessReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case userTypes.GET_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        businesses: getData()?.data,
      };

    default:
      return state;
  }
};

export default businessReducer;
