import { customcategoryTypes } from "../types";

const initialstate = {
  customcategories: [],
};

const customcategoryReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case customcategoryTypes.GET_ALL_CUSTOMCATEGORIES_SUCCESS:
      return {
        ...state,
        customcategories: getData()?.data,
      };


    default:
      return state;
  }
};

export default customcategoryReducer;
