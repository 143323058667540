import { utilTypes } from "../types";

export const getDashboard = (data) => {
  return {
    type: utilTypes.GET_DASHBOARD,
    payload: {
      request: {
        url: "/dashboard/",
        method: "get",
      },
    },
  };
};

export const changePage = (data) => {
  return {
    type: utilTypes.SET_PAGE,
    payload: data
  };
}

export const changeRowsPage = (data) => {
  return {
    type: utilTypes.SET_ROWS_PAGE,
    payload: data
  };
}
