import React from "react";
import InputContainer from "./InputContainer";
import { Controller } from "react-hook-form";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/moment';
import { DATEFORMAT } from "../../../contants";

const TextBox = React.forwardRef((props, ref) => {
  const { label, control, placeholder, type, size, error, rules, required } = props;

  return (

    <InputContainer size={size}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          control={control}
          {...props}
          render={(props) => (
            <KeyboardDatePicker
              label={`${label} ${required ? '*' : ''}`}
              error={error}
              disablePast
              format={DATEFORMAT}
              value={props.value}
              helperText={error}
              inputVariant="outlined"
              fullWidth
              onChange={(date) => { props.onChange(date) }}
            />
          )} // props contain
          rules={rules}
        />
      </MuiPickersUtilsProvider>

    </InputContainer>


  );
});
export default TextBox;
