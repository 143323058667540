import { frameFieldsTypes, frameTypes } from "../types";

const initialstate = {
  fields: [],
};

const frameFieldReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case frameFieldsTypes.GET_ALL_FRAME_FIELDS_SUCCESS:
      return {
        ...state,
        fields: getData()?.data,
      };

    default:
      return state;
  }
};

export default frameFieldReducer;
