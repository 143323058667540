import React from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import RBCToolbar from "./toolBar";
import { Dialog, DialogTitle, Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";

const localizer = momentLocalizer(moment);
const eventStyleGetter = (event) => {
    var style = {
        backgroundColor: '#00ab55',
        color: '#fff',
        textTransform: 'capitalize',
    };
    return {
        style: style
    };
}
const useStyles = makeStyles((theme) => ({
    modalTitle: {
        fontWeight: theme.palette.fontWeights.bold,
        textTransform: 'capitalize',
        fontSize: theme.palette.fontSizes.md,
        color: theme.palette.text.primary
    },
}));

function SimpleDialog(props) {
    const { onClose, events, handleEventSelect, handleSelect, open } = props;
    const classes = useStyles();
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} >
            <DialogTitle onClose={handleClose}>
                <Typography className={classes.modalTitle}>All Festival Posts</Typography>
            </DialogTitle>
            <Divider />
            <List>
                {events.map((event) => (
                    <ListItem button onClick={() => handleEventSelect(event)} key={event}>
                        <ListItemText style={{ textTransform: 'capitalize' }} primary={event.title} />
                    </ListItem>
                ))}
                <ListItem autoFocus button color="primary" onClick={(e) => { handleSelect(e); handleClose() }}>
                    <ListItemText primary={<Typography variant={"button"}> Add festival post</Typography>} />
                </ListItem>
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

const ScheduleCalendar = ({ events: propsEvents, handleSelect, handleEventSelect }) => {
    const [state, setState] = React.useState({
        open: false,
        events: []
    });

    const handleClose = () => {
        setState({ open: false, events: [] });
    };
    return (
        <>
            <Calendar
                localizer={localizer}
                events={propsEvents}
                selectable
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                onSelectEvent={(event) => handleEventSelect(event)}
                onSelectSlot={handleSelect}
                views={['month']}
                min={new Date()}
                onShowMore={(events, date) => setState({ open: true, events })}
                components={{
                    toolbar: RBCToolbar
                }}
                style={{ height: '130vh', }}
            />
            <SimpleDialog events={state.events} handleSelect={handleSelect} handleEventSelect={handleEventSelect} open={state.open} onClose={handleClose} />
        </>
    )
}

export default ScheduleCalendar;
