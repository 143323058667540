import { loadingTypes } from '../types';

const loadingReducer = (state = [], action) => {
    switch (action.type) {
        case loadingTypes.SET_LOADING:
            return [...state, action.payload];

        case loadingTypes.CLEAR_LOADING:
            return state.filter(f => f !== action.payload);
        default:
            return state;
    }
};
export default loadingReducer;