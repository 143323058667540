import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  title: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  header: {
    margin: theme.spacing(5, 0, 2, 0),
    fontWeight: theme.palette.fontWeights.semiBold,
  },
}));

const Help = () => {
  const classes = useStyles();
  const [sticky, setSticky] = React.useState();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Help & Support
      </Typography>

      {/* <Typography className={classes.header} variant="h5">
        Send message on whatsapp
      </Typography>
      <Typography className={classes.text}>
        <Link
          href="https://api.whatsapp.com/send?phone=+919726255365&text=Inquiry%20for%20Panchayatpost"
          target="_blank"
        >
          Send a message
        </Link>
      </Typography> */}
      <Typography className={classes.header} variant="h5">
        Whatsapp Number
      </Typography>
      <Typography className={classes.text}>
        <Link href="https://wa.link/xnljiz" target="_blank">
          +91 9726255365
        </Link>
      </Typography>
      <Typography className={classes.header} variant="h5">
        Email
      </Typography>
      <Typography className={classes.text}>
        <Link href="mailto:panchayatpost108@gmail.com" target="_blank">
          panchayatpost108@gmail.com
        </Link>
      </Typography>
    </div>
  );
};
export default Help;
