import businessApi from "../api/businessApi";
import { userTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";

export const getAllBusinesses = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get businesses",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.GET_BUSINESS_DETAILS,
      payload: {
        request: {
          url: businessApi.GET_ALL_BUSINESSES,
          method: "GET",
          params: { id: data }
        },
      },
    });
};

export const createBusiness = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'businessLogo') {
      if (data['businessLogo'].length > 0) {
        console.log('data[businessLogo].length', data['businessLogo'].length);
        data['businessLogo'].forEach((item, index) => {
          formData.append(`businessLogo`, data['businessLogo'][index])
        })
      } else {
        formData.delete('businessLogo')
      }
    } else {
      formData.append(key, data[key])
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Business created successfully",
      errorMessage: "Failed to create business",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.CREATE_BUSINESS,
      payload: {
        request: {
          url: businessApi.CREATE_BUSINESS,
          method: "POST",
          data: formData
        },
      },
    });
};

export const updateBusiness = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'businessLogo') {
      formData.append('businessLogo', {})
      data['businessLogo'].forEach((item, index) => {
        formData.append(`businessLogo`, data['businessLogo'][index])
      })
    } else {
      formData.append(key, data[key])
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Business updated successfully",
      errorMessage: "Failed to update business",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.UPDATE_BUSINESS,
      payload: {
        request: {
          url: businessApi.UPDATE_BUSINESS,
          method: "PUT",
          data: formData
        },
      },
    });
};

export const deleteBusiness = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "User deleted successfully",
      errorMessage: "Failed to delete business",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: userTypes.DELETE_BUSINESS,
      payload: {
        request: {
          url: businessApi.DELETE_BUSINESS,
          method: "DELETE",
          data: data
        },
      },
    });
};
