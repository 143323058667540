import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AllPosts from "./AllPosts";
import { getAllCategories } from "../../redux/action/categoryActions";
import { getAllPosts } from "../../redux/action/postsActions";
import FestivalPosts from "./FestivalPost";
import Tabbar from "../../components/Tabbar";

const Posts = () => {
  const dispatch = useDispatch();

  const fixedFestival = useSelector((state) => state.festival.festivals)
  const category = useSelector((state => state.category.categories))


  React.useEffect(() => {
    dispatch(getAllPosts());
    dispatch(getAllCategories(true))
  }, [])

  const tabComp = [
    {
      title: 'Festival Posts',
      component: <FestivalPosts />
    },
    // {
    //   title: 'All Posts',
    //   component: <AllPosts category={category} />
    // },

  ]

  return (
    // <Tabbar data={tabComp} />
    <FestivalPosts />
  );
};

export default Posts;