import greetingApi from "../api/greetingApi";
import { greetingTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllGreetings = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get subcategories",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: greetingTypes.GET_ALL_GREETINGS,
      payload: {
        request: {
          url: greetingApi.GET_ALL_GREETINGS,
          method: "GET",
          params: { id: data }
        },
      },
    });
};

export const createGreeting = (data, cb, errorCb) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key) => {
  //   if (key === 'subcategoryImage') {
  //     if (data['subcategoryImage'].length > 0) {
  //       console.log('data[subcategoryImage].length', data['subcategoryImage'].length);
  //       data['subcategoryImage'].forEach((item, index) => {
  //         formData.append(`subcategoryImage`, data['subcategoryImage'][index])
  //       })
  //     } else {
  //       formData.delete('subcategoryImage')
  //     }
  //   } else {
  //     formData.append(key, data[key])
  //   }

  // });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Greeting created successfully",
      errorMessage: "Failed to create subcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: greetingTypes.CREATE_GREETINGS,
      payload: {
        request: {
          url: greetingApi.CREATE_GREETINGS,
          method: "POST",
          data: data
        },
      },
    });
};

export const updateGreeting = (data, cb, errorCb) => {
  // const formData = new FormData();
  // Object.keys(data).forEach((key, i) => {
  //   formData.append(key, data[key])
  //   if (key === 'subcategoryImage') {
  //     if (Array.isArray(data.subcategoryImage)) { formData.append(key, data.subcategoryImage[0]) }
  //   }
  // });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Greeting created successfully",
      errorMessage: "Failed to update subcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: greetingTypes.UPDATE_GREETINGS,
      payload: {
        request: {
          url: greetingApi.UPDATE_GREETINGS,
          method: "PUT",
          data: data
        },
      },
    });
};

export const getGreetingImages = (data, cb, errorCb) => {
  return {
    type: greetingTypes.GET_GREETING_IMAGES,
    payload: {
      request: {
        url: greetingApi.GET_GREETING_IMAGES,
        method: "GET",
        params: { id: data }
      },
    },
  };
}


export const uploadGreetingImages = (data, cb, errorCb) => {
  const formData = new FormData();

  data.images.forEach((k) => {
    formData.append('images', k)
  })
  formData.append('id', data.id)
  formData.append('categoryId', data.categoryId)


  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Greeting images added  successfully",
      errorMessage: "Failed to add subcategory images",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: greetingTypes.UPLOAD_GREETING_IMAGES,
      payload: {
        request: {
          url: greetingApi.UPLOAD_GREETING_IMAGES,
          method: "POST",
          data: formData
        },
      },
    });
};

export const deleteGreeting = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Greeting deleted successfully",
      errorMessage: "Failed to delete subcategory",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: greetingTypes.DELETE_GREETINGS,
      payload: {
        request: {
          url: greetingApi.DELETE_GREETINGS,
          method: "DELETE",
          data: data
        },
      },
    });
};
