import themeSampleApi from "../api/themeSampleApi";
import { themeSampleTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllThemeSample = (status, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get categories",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: themeSampleTypes.GET_ALL_THEME_SAMPLES,
      payload: {
        request: {
          url: themeSampleApi.GET_ALL_THEME_SAMPLES,
          method: "GET",
          params: {
            status: status,
          }
        },
      },
    });
};

export const createThemeSample = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    formData.append(key, data[key])
    if (key === 'themeSampleImage') {
      if (Array.isArray(data.themeSampleImage)) { formData.append(key, data.themeSampleImage[0]) }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "ThemeSample created successfully",
      errorMessage: "Failed to create category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: themeSampleTypes.CREATE_THEME_SAMPLES,
      payload: {
        request: {
          url: themeSampleApi.CREATE_THEME_SAMPLES,
          method: "POST",
          data: formData
        },
      },
    });
};

export const updateThemeSample = (data, cb, errorCb) => {
  const formData = new FormData();
  Object.keys(data).forEach((key, i) => {
    formData.append(key, data[key])
    if (key === 'themeSampleImage') {
      if (Array.isArray(data.themeSampleImage)) { formData.append(key, data.themeSampleImage[0]) }
    }
  });
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "ThemeSample created successfully",
      errorMessage: "Failed to update category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: themeSampleTypes.UPDATE_THEME_SAMPLES,
      payload: {
        request: {
          url: themeSampleApi.UPDATE_THEME_SAMPLES,
          method: "PUT",
          data: formData
        },
      },
    });
};

export const deleteThemeSample = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "ThemeSample deleted successfully",
      errorMessage: "Failed to delete category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: themeSampleTypes.DELETE_THEME_SAMPLES,
      payload: {
        request: {
          url: themeSampleApi.DELETE_THEME_SAMPLES,
          method: "DELETE",
          data: data
        },
      },
    });
};
