import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  CircularProgress,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Typography,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { Autocomplete } from "@material-ui/lab";
import { festivalTypes } from "../../../redux/types";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.gray[700]}`,
    color: theme.palette.text.primary,
  },
  modalTitle: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    fontSize: theme.palette.fontSizes.md,
    color: theme.palette.text.primary,
  },
  img: {
    width: 140,
    height: 140,
    transition: "all 0.2s ease-in-out",
    border: "3px solid transparent",
    padding: theme.spacing(0.2),
    cursor: "pointer",
    borderRadius: theme.palette.radius.medium,
    objectFit: "cover",
  },
  selected: {
    filter: "brightness(0.8)",
    borderColor: theme.palette.primary.main,
    transform: "scale(0.95)",
  },
  icon: {
    color: "rgba(255,255,255,0.8)",
    fontSize: theme.palette.fontSizes["6xl"],
    position: "absolute",
    top: "50%",
    left: "50%",
    opacity: 0,
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  selectedIcon: {
    opacity: 1,
  },
  deleteBtn: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.white,
      background: theme.palette.error.main,
    },
  },
}));
const FestivalActionModal = ({
  open,
  onClose,
  title,
  size,
  data = {},
  festivals,
  mode,
  onDelete,
  onSubmit,
  isLoading = false,
}) => {
  const classes = useStyles();
  // const isLoading = useSelector((state) => state.util.spinner);
  // const isLoading = useSelector(state =>
  //   loadingSelector(festivalTypes.CREATE_FESTIVALS, state),
  // );
  const initialValues = {
    postStartDate: undefined,
    postEndDate: undefined,
    postName: undefined,
    festivalId: undefined,
    // images: [],
    // postImages: [],
    status: undefined,
    errors: {},
  };
  const [state, setState] = React.useState(initialValues);

  // const { images } = state

  const handleValidation = () => {
    let fields = state;
    let errors = {};
    let formIsValid = true;

    if (!fields["postName"]) {
      formIsValid = false;
      errors["postName"] = `PostName is required`;
    } else {
      errors["postName"] = undefined;
    }

    if (!fields["festivalId"]) {
      formIsValid = false;
      errors["festivalId"] = `Festival is required`;
    } else {
      errors["festivalId"] = undefined;
    }

    if (!fields["status"]) {
      formIsValid = false;
      errors["status"] = `Status is required`;
    } else {
      errors["status"] = undefined;
    }
    console.log("state", state);
    setState({ ...state, errors: errors });
    return formIsValid;
  };

  const handleChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  const handlePostName = ({ name, value }) => {
    const foundFest = festivals.find((f) => f.id === value || f._id === value);
    setState({ ...state, [name]: value, postName: foundFest.festivalName });
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      onSubmit(state);
    }
  };

  const getFestivalName = () => {
    const filter = state.festivalId
      ? festivals.find(
          (f) => f.id === state.festivalId || f._id === state.festivalId
        )
      : undefined;
    return filter;
  };

  // const handleFestivalChange = (value) => {
  //     if (value) {
  //         console.log('value', value);
  //         const filteredFestival = festivals.find(fe => fe._id === value)
  //         let images = filteredFestival.festivalImages.map((i) => {
  //             return { isSelected: true, imageUrl: i }
  //         })
  //         setState({ ...state, festivalId: value, images: images, postImages: images.map(se => { return se.imageUrl }) })
  //     } else {
  //         setState({ ...state, festivalId: undefined, images: [] })
  //     }
  // }

  // const handleImageClick = ({ isSelected, imgIndex }) => {
  //     let collection = state.images
  //     collection[imgIndex].isSelected = !isSelected
  //     const selectedImages = collection.filter((fe) => fe.isSelected === true)
  //     setState({ ...state, images: collection, postImages: selectedImages.map(se => { return se.imageUrl }) })
  // }

  const handleClose = () => {
    console.log("handleClose");
    setState(initialValues);
    onClose();
  };

  React.useEffect(() => {
    // const editImages = festivals.find(fe => fe._id === data.festivalId)?.festivalImages.filter(x => !data.postImages.includes(x)).map((img) => { return { isSelected: false, imageUrl: img } });
    if (Object.keys(data).length != 0) {
      setState({
        ...state,
        postStartDate: data.start,
        postEndDate: data.end,
        postName: data.postName,
        festivalId: data.festivalId,
        // images: editImages && [...data.postImages?.map((img) => { return { isSelected: true, imageUrl: img } }), ...editImages,],
        // postImages: data.postImages,
        status: data.status || "true",
      });
    }
  }, [data]);
  console.log("state", state);
  return (
    open && (
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        maxWidth={size || "lg"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: "5px 20px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.modalTitle}
            >{`${mode} ${title}`}</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* <Grid item lg={12}>
                            <TextField
                                name="postName"
                                label="Post Name"
                                onChange={({ target: { name, value } }) => handleChange({ name, value })}
                                value={state.postName}
                                variant="outlined"
                                fullWidth
                                error={state.errors["postName"]}
                                helperText={state.errors["postName"]}
                            />
                        </Grid> */}
            <Grid item lg={6}>
              {/* <TextField
                select
                fullWidth
                name="festivalId"
                variant="outlined"
                size="Normal"
                label="Festival"
                value={state.festivalId}
                error={state.errors["festivalId"]}
                helperText={state.errors["festivalId"]}
                onChange={({ target: { name, value } }) =>
                  handlePostName({ name, value })
                }
              >
                {festivals.map((opt) => {
                  return (
                    <MenuItem value={opt._id || opt.id}>
                      {`${opt.festivalName} ${
                        opt.isFixedDate
                          ? `(${moment(opt.festivalDate).format(DATEFORMAT)})`
                          : ""
                      } `}
                    </MenuItem>
                  );
                })}
              </TextField> */}
              <Autocomplete
                name="festivalId"
                label="Festival"
                getOptionLabel={(option) => option.festivalName}
                options={festivals}
                onChange={(event, newValue) => {
                  handlePostName({
                    name: "festivalId",
                    value: newValue?.id || newValue?._id,
                  });
                }}
                value={getFestivalName()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Festival"
                    error={state.errors["festivalId"]}
                    helperText={state.errors["festivalId"]}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                select
                fullWidth
                name="status"
                variant="outlined"
                size="Normal"
                label="Status"
                value={state.status}
                error={state.errors["status"]}
                helperText={state.errors["status"]}
                onChange={({ target: { name, value } }) =>
                  handleChange({ name, value })
                }
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </TextField>
            </Grid>
            {/* {images && images.length > 0 && (
                            <Grid item lg={12}>
                                <Card style={{ marginBottom: 20 }} variant="outlined">
                                    <CardHeader
                                        subheader={`${images?.length} images`}
                                    />
                                    <Divider />
                                    <CardContent >
                                        <Grid container spacing={2}>
                                            {images && images.length > 0 && images?.map(({ imageUrl, isSelected }, imgIndex) => {
                                                return (
                                                    <Grid item lg={2} onClick={() => handleImageClick({ isSelected, imgIndex })} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', width: '100%', height: '100%' }}>
                                                        <img className={clsx(classes.img, isSelected && classes.selected)} src={BASEIMAGEURL +  imageUrl} />
                                                        <CheckIcon className={clsx(classes.icon, isSelected && classes.selectedIcon)} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )} */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: 10 }}>
          {mode != "Add" && (
            <Button
              variant="text"
              className={classes.deleteBtn}
              onClick={() => onDelete(data)}
            >
              Delete Festival Post
            </Button>
          )}
          <Button
            disabled={isLoading}
            variant="text"
            onClick={() => handleClose()}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Loading...&nbsp;&nbsp;
                <CircularProgress color="inherit" size={15} />
              </>
            ) : mode === "Add" ? (
              "Submit"
            ) : (
              "Save changes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default FestivalActionModal;
