import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { emailRegex } from "../../utils/regex";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/action/userActions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 50,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: theme.palette.fontSizes.xl,
    marginBottom: 10,
  },
  subTitle: {
    color: theme.palette.gray[1200],
    fontSize: theme.palette.fontSizes.semibase,
  },
  card: {
    width: "100%",
  },
  helperText: {
    fontSize: "0.9rem",
    color: "red",
    fontWeight: 400,
  },
  ForgotButton: {
    textTransform: "none",
  },
  textField: {
    borderRadius: theme.palette.radius.base,
  },
  btn: {
    fontSize: theme.palette.fontSizes.semibase,
    padding: "10px 22px",
  },
  alert: {
    borderRadius: theme.palette.radius.base,
  },
}));

const LoginForm = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory()
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { handleSubmit, control, errors } = methods;
  const onSubmit = (data) => {
    setLoading(!loading);
    dispatch(loginUser(data, () => {
      history.push('/dashboard')
    }));
  };
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const user = useSelector((state) => state.user);
  return (
    <div className={classes.root}>
      <Card elevation={0} className={classes.card}>
        <CardContent>
          <Typography variant="h5" className={classes.title}>
            Sign in to Admin Panel
          </Typography>
          <Typography variant="h6" className={classes.subTitle}>
            Enter your details below.
          </Typography>
        </CardContent>

        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            {/* <TextField control={control} name="email" label="Email address"
              type="email"
              required
            /> */}
            <Controller
              name="email"
              fullWidth
              label="Email"
              control={control}
              rules={{
                required: { value: true, message: "Email is required" },
                pattern: {
                  value: emailRegex,
                  message: "Invalid Email address",
                },
              }}
              render={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label="Email address"
                  variant="outlined"
                  type="email"
                  placeholder="Enter Email"
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.textField,
                    },
                  }}
                  required={true}
                />
              )} // props contains: onChange, onBlur and value
            />
            <Typography className={classes.helperText}>
              {errors?.email?.message}
            </Typography>
          </CardContent>
          <CardContent>
            {/* <TextField control={control} name="password" label="Password"
              helperText={
                errors.password
                  ? errors.password.message || "Password req"
                  : undefined
              }
              FormHelperTextProps={{ className: classes.helperText }}
              rules={{
                required: { value: true, message: "Password is required" },
              }}
              type="password"
              required
            /> */}

            <Controller
              name="password"
              fullWidth
              label="Password"
              control={control}
              rules={{
                required: { value: true, message: "Password is required" },
              }}
              render={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  label="Password"
                  placeholder="Enter Password"
                  required={true}
                  variant="outlined"
                  type={values.showPassword ? "text" : "password"}
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.textField,
                    },
                  }}
                  helperText={
                    errors.password
                      ? errors.password.message || "Password req"
                      : undefined
                  }
                  FormHelperTextProps={{ className: classes.helperText }}
                />
              )} // props contains: onChange, onBlur and value
            />
          </CardContent>
          <CardContent>
            <Grid container alignItems="center" justify="space-between">
              <Grid item sm={12} md={12} lg={12} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={user?.isLoading}
                  className={classes.btn}
                >
                  {user?.isLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Grid>
              {/* <Grid item>
                <Button className={classes.ForgotButton}>
                  <Typography color="textPrimary">Forgot Password ?</Typography>
                </Button>
              </Grid> */}
            </Grid>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};
export default LoginForm;
