import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  CircularProgress,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { DATEFORMAT } from "../../../contants";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { userTypes } from "../../../redux/types";
import loadingSelector from "../../../redux/selectors/loadingSelector";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.gray[700]}`,
    color: theme.palette.text.primary,
  },
  modalTitle: {
    fontWeight: theme.palette.fontWeights.bold,
    textTransform: "capitalize",
    fontSize: theme.palette.fontSizes.md,
    color: theme.palette.text.primary,
  },
  img: {
    width: 140,
    height: 140,
    transition: "all 0.2s ease-in-out",
    border: "3px solid transparent",
    padding: theme.spacing(0.2),
    cursor: "pointer",
    borderRadius: theme.palette.radius.medium,
    objectFit: "cover",
  },
  selected: {
    filter: "brightness(0.8)",
    borderColor: theme.palette.primary.main,
    transform: "scale(0.95)",
  },
  icon: {
    color: "rgba(255,255,255,0.8)",
    fontSize: theme.palette.fontSizes["6xl"],
    position: "absolute",
    top: "50%",
    left: "50%",
    opacity: 0,
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  selectedIcon: {
    opacity: 1,
  },
  deleteBtn: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.white,
      background: theme.palette.error.main,
    },
  },
}));
const AssignSubscriptionModal = ({
  open,
  onClose,
  title,
  size,
  data = {},
  mode,
  onDelete,
  onSubmit,
}) => {
  const classes = useStyles();
  // const isLoading = useSelector((state) => state.util.spinner);
  const isLoading = useSelector((state) =>
    loadingSelector(userTypes.ASSIGN_SUBSCRIPTION, state)
  );
  const initialValues = {
    subscriptionId: undefined,
    errors: {},
  };
  const [state, setState] = React.useState(initialValues);

  // const { images } = state
  const subscriptions = useSelector(
    (state) => state.subscription.subscriptions
  );

  const handleValidation = () => {
    let fields = state;
    let errors = {};
    let formIsValid = true;

    if (!fields["subscriptionId"]) {
      formIsValid = false;
      errors["subscriptionId"] = `Subscription is required`;
    } else {
      errors["subscriptionId"] = undefined;
    }
    setState({ ...state, errors: errors });
    return formIsValid;
  };

  const handleChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      onSubmit(state);
    }
  };

  const handleClose = () => {
    onClose();
    setState(initialValues);
  };
  // React.useEffect(() => {
  //     if (data) {
  //         setState({ ...state, subscriptionId: data?.subscription?.subscriptionId })
  //     }
  // }, [data])

  return (
    open && (
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle style={{ padding: "5px 20px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.modalTitle}
            >{`${mode} ${title}`}</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {data?.subscription?.startDate && (
              <>
                <Grid item lg={10}>
                  <Alert icon={false} severity="info">
                    {"  "} Start :{" "}
                    {moment(data?.subscription?.startDate).format(DATEFORMAT)}{" "}
                    End :{" "}
                    {moment(data?.subscription?.endDate).format(DATEFORMAT)}
                  </Alert>
                </Grid>
                <Grid item lg={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onDelete(data)}
                  >
                    Delete
                  </Button>
                </Grid>
              </>
            )}
            <Grid item lg={12}>
              <TextField
                select
                fullWidth
                name="subscriptionId"
                variant="outlined"
                size="Normal"
                label="Subscription"
                value={
                  state.subscriptionId || data?.subscription?.subscriptionId
                }
                error={state.errors["subscriptionId"]}
                helperText={state.errors["subscriptionId"]}
                onChange={({ target: { name, value } }) =>
                  handleChange({ name, value })
                }
              >
                {subscriptions.map((opt) => {
                  return (
                    <MenuItem value={opt._id || opt.id}>
                      {opt.subscriptionName}
                      {` (${opt.subscriptionDuration} Month)`}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: 10 }}>
          <Button
            disabled={isLoading}
            variant="text"
            onClick={() => handleClose()}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Loading...&nbsp;&nbsp;
                <CircularProgress color="inherit" size={15} />
              </>
            ) : mode === "Add" ? (
              "Submit"
            ) : (
              "Save changes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

export default AssignSubscriptionModal;
