import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import InfoCard from "../../components/InfoCard";
import UserIcon from "@material-ui/icons/PeopleAltTwoTone";
import { numberWithCommas } from "../../contants";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../redux/action/utilActions";
import { CakeOutlined, ImageTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
}));

const DashComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { totalUsers, activeUserCount, festivalCount, totalGreetings } =
    useSelector((state) => state.util.dashboard);
  // console.log("userSubscription", userSubscription);
  const INfoArray = [
    {
      title: "Total Users",
      icon: UserIcon,
      count: totalUsers || 0,
    },
    {
      title: "Total Active Users",
      icon: UserIcon,
      count: activeUserCount || 0,
    },
    {
      title: "Total Festivals",
      icon: CakeOutlined,
      count: festivalCount || 0,
    },
    {
      title: "Total Greetings",
      icon: ImageTwoTone,
      count: totalGreetings || 0,
    },
  ];

  const tableHeaders = [
    { title: "Transaction Id", key: "orderId" },
    { title: "Mobile", key: "mobile" },
    { title: "email", key: "email" },
    { title: "Amount", key: "amount" },
    { title: "Payment By", key: "paymentId" },
    { title: "Payment Method", key: "method" },
    { title: "Status", key: "paymentStatus" },
  ];

  React.useEffect(() => {
    dispatch(getDashboard());
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {INfoArray.map((item, index) => {
          return (
            <InfoCard
              key={item.title}
              title={item.title}
              icon={item.icon}
              currency={item.currency}
              count={numberWithCommas(item.count)}
            />
          );
        })}
      </Grid>
    </div>
  );
};
export default DashComponent;
