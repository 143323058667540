import { subscriptionTypes } from "../types";

const initialstate = {
  subscriptions: [],
};

const subscriptionReducer = (state = initialstate, action) => {
  const getData = () => action.payload.data;
  switch (action.type) {

    case subscriptionTypes.GET_ALL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: getData()?.data,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
