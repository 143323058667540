import React from "react";
import { useSelector } from "react-redux";
import AuthComponent from "../pages/auth";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import SnackComponent from "../components/Common/Snackbar";
import SpinnerModal from "../components/Common/Modals/SpinnerModal";
import checkIfAppReady from "../helper/checkIfAppReady";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CssBaseline } from "@material-ui/core";
import Sidebar from "../components/Sidebar";
import DashBoardComponent from "../pages/dashboard";
import UsersComponent from "../pages/users";
import UserBusinessComponent from "../pages/userbusiness";
import CategoryComponent from "../pages/categories";
import SubCategoryComponent from "../pages/subcategories";
import FestivalComponent from "../pages/festivals";
import ReportsComponent from "../pages/reports";
import PostComponent from "../pages/posts";
import FrameComponent from "../pages/frames";
import NavBar from "../components/Appbar";
import ProtectedRoute from "./ProtectedRoute";
import SubscriptionComponent from "../pages/subscriptions";
import HomeComponent from "../pages/home";
import Privacy from "../pages/privacy";
import Help from "../pages/help";
import Refund from "../pages/refund";
import Terms from "../pages/terms";
import SliderComponent from "../pages/slider";
import ThemeSample from "../pages/themesample";
import Greeting from "../pages/greetings";
import AppUsers from "../pages/users/AppUsers";
import AdminUsers from "../pages/users/AdminUsers";
import SubscribedUsers from "../pages/users/SubscribedUsers";
import CustomCategories from "../pages/customCategories";
import AccountDelete from "../pages/account-delete";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
  container: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(14),
    height: "100%",
    width: "100%",
  },
  box: {
    backgroundColor: "white",
    borderRadius: 10,
    height: "100%",
    padding: 20,
    margin: 20,
  },
}));

const Routes = () => {
  const classes = useStyles();
  const superadmin = "superadmin";
  const admin = "admin";
  const employee = "employee";
  // const spinner = useSelector((state) => state.util.spinner);
  const { isLogged } = useSelector((state) => state.user);

  const ready = checkIfAppReady();
  const getHomepage = () => {
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    return <Redirect to="/dashboard" />;
  };

  React.useEffect(() => {
    getHomepage();
  }, [isLogged]);

  return ready ? (
    <div className={classes.root}>
      {/* {spinner && <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 9999, }}><LinearProgress /></div>} */}
      {/* {spinner && <SpinnerModal />} */}
      <SnackComponent />
      <BrowserRouter>
        {/* {!isLogged ? (
          <AuthComponent />
        ) : (
          <DashBoardRoutes />
        )} */}
        <Switch>
          <Route path="/" exact component={HomeComponent} />
          <Route path="/privacy-policy" exact component={Privacy} />
          <Route path="/delete-account" exact component={AccountDelete} />

          <Route path="/help-support" exact component={Help} />
          <Route path="/refund-cancellation" exact component={Refund} />
          <Route path="/terms-condition" exact component={Terms} />

          <Route path="/admin" exact render={() => getHomepage()} />
          <Route path="/login" exact component={AuthComponent} />
          <Box
            style={{ display: "flex", minHeight: "100vh", background: "white" }}
          >
            <CssBaseline />
            <Box style={{ width: 280, flexShrink: 0 }}>
              <Sidebar />
            </Box>
            {/* <Sidebar /> */}
            <NavBar />
            <div className={classes.container}>
              <ProtectedRoute
                exact
                path="/dashboard"
                component={DashBoardComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin]}
                path="/appusers"
                component={AppUsers}
              />
              <ProtectedRoute
                exact
                roles={[superadmin]}
                path="/adminusers"
                component={AdminUsers}
              />
              <ProtectedRoute
                exact
                roles={[superadmin]}
                path="/subscribedusers"
                component={SubscribedUsers}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/festivals"
                component={FestivalComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/categories"
                component={CategoryComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/subcategories/:id"
                component={SubCategoryComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/custom-categories"
                component={CustomCategories}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/greetings"
                component={Greeting}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/business/:id"
                component={UserBusinessComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/posts"
                component={PostComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/frames"
                component={FrameComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/reports"
                component={ReportsComponent}
              />
              <ProtectedRoute
                exact
                roles={[superadmin, admin, employee]}
                path="/subscriptions"
                component={SubscriptionComponent}
              />
              <ProtectedRoute
                exact
                path="/sliders"
                component={SliderComponent}
              />
              <ProtectedRoute
                exact
                path="/themesamples"
                component={ThemeSample}
              />
            </div>
          </Box>
        </Switch>
      </BrowserRouter>
    </div>
  ) : (
    <SpinnerModal />
  );
};
export default Routes;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { useSelector } from "react-redux";
// import AuthIndex from "../pages/auth";
// import { BrowserRouter, Route } from "react-router-dom";
// import SnackComponent from "../components/Common/Snackbar";
// import SpinnerModal from "../components/Common/Modals/SpinnerModal";
// import checkIfAppReady from "../helper/checkIfAppReady"
// import DashBoardRoutes from "./DashboardRoutes";
// import HomeComponent from '../pages/home'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//     backgroundColor: theme.palette.white,
//     [theme.breakpoints.down("md")]: {
//       backgroundColor: "white",
//     },
//   },
// }));

// const Routes = () => {
//   const classes = useStyles();

//   const isLogged = useSelector((state) => state.user.isLogged);
//   // const spinner = useSelector((state) => state.util.spinner);

//   const ready = checkIfAppReady();
//   return (
//     <div>
//       {/* {spinner && <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 9999, }}><LinearProgress /></div>} */}
//       {/* {spinner && <SpinnerModal />} */}
//       <SnackComponent />
//       <BrowserRouter>
//         <Route path="/" exact component={HomeComponent} />{
//           ready ? !isLogged ? (
//             <AuthIndex />
//           ) : (
//             <div className={classes.root}>
//               <DashBoardRoutes />
//             </div>
//           ) : <SpinnerModal />}
//       </BrowserRouter>
//     </div>
//   );
// };
// export default Routes;
