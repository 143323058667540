import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import { getAdminUsers, createUser, deleteUser, updateUser } from "../../redux/action/userActions";
// import DateRangePicker from "react-bootstrap-daterangepicker";
import { ROLES } from "../../contants";
import { userTypes } from "../../redux/types";
import loadingSelector from "../../redux/selectors/loadingSelector";

const AdminUsers = () => {
  const { users: data, totalLength } = useSelector((state) => state.user);
  const { page, rowsPerPage } = useSelector(state => state.util)
  const isLoading = useSelector(state =>
    loadingSelector(userTypes.GET_ADMIN_USERS, state),
  );
  const pagination = { page: page, limit: rowsPerPage }
  const formData = [
    {
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Type Name",
      required: true,
      size: 6,
    },
    {
      type: "email",
      name: "email",
      label: "Email",
      placeholder: "Type Email",
      required: true,
      size: 6,
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Type Password",
      required: true,
      size: 6,
    },
    {
      type: "select",
      name: "role",
      size: 6,
      required: true,
      label: "Role",
      options: ROLES,
      optionLabelProp: "title",
      optionValueProp: "value",
    },
    {
      type: "select",
      name: "status",
      size: 6,
      required: true,
      label: "Status",
      options: [
        {
          title: "Active",
          value: "true",
        },
        {
          title: "Inactive",
          value: "false",
        },
      ],
      optionLabelProp: "title",
      optionValueProp: "value",
    },
  ]

  const tableHeaders = [
    { title: "Name", key: "name" },
    { title: "Role", key: "role" },
    {
      title: "Email",
      key: "email",
    },
    { title: "Status", key: "status" },
    { title: "Created At", key: "createdAt" },
  ];

  const pageProps = {
    title: "Admin Users",
    layout: "datatable",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["name", 'mobile'],
    searchByLabel: "Name or Mobile",
    modalSize: 'md',
    sortable: true,
    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    tableRowActions: [],
    pageHeaderActions: [],
    onAdd: createUser,
    onEdit: updateUser,
    onDelete: deleteUser,
    totalLength: totalLength,
    getData: (e) => getAdminUsers(e),
    isLoading: isLoading
  };
  // React.useEffect(() => {
  //   dispatch(getAdminUsers());
  // }, []);
  return (
    <PageCreator {...pageProps} />
  );
};

export default AdminUsers;