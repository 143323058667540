import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageCreator from "../../components/PageCreator";
import {
  getAllSliders,
  createSlider,
  deleteSlider,
  updateSlider,
} from "../../redux/action/sliderActions";
import { sliderTypes } from "../../redux/types";

const Sliders = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.slider.sliders);

  const formData = [
    {
      type: "text",
      name: "sliderName",
      label: "Slider Name",
      placeholder: "Type Slider Name",
      required: true,
      size: 12,
    },
    {
      type: "text",
      name: "sliderLink",
      label: "Slider Link",
      placeholder: "Type Slider Link",
      required: false,
      size: 12,
    },
    {
      type: "file",
      name: "sliderImage",
      label: "Slider Image",
      placeholder: "Type Slider Image",
      required: true,
      size: 12,
    },
  ];

  const tableHeaders = [
    { title: "Name", key: "sliderName" },
    { title: "Image", key: "sliderImage", type: "image" },
    {
      title: "Link",
      key: "sliderLink",
      // renderRow: (row) => {
      //   console.log("row", row);
      // },
    },
  ];

  const pageProps = {
    title: "Sliders",
    layout: "tabular",
    formData: formData,
    tableHeaders: tableHeaders,
    tableData: data,
    searchByField: ["sliderName"],
    searchByLabel: "name",
    modalSize: "sm",
    sortable: true,

    paginated: true,

    enableImport: false,
    importHeaders: [],

    importData: [],

    // hideEdit: false,
    // hideDelete: true,
    // hideAdd: true,
    // hideView: true,
    pageHeaderActions: [],

    defaultFormValues: {},
    deleteVariableTitle: undefined,
    onAdd: createSlider,
    onEdit: updateSlider,
    onDelete: deleteSlider,
    onImport: () => {},

    getData: (e) => getAllSliders(),
    getImportData: () => {},
    afterAddSuccess: () => {},
    afterEditSuccess: () => {},
    afterDeleteSuccess: () => {},
    afterImportSuccess: () => {},
    loadingTypes: [sliderTypes.CREATE_SLIDERS, sliderTypes.UPDATE_SLIDERS],
  };
  React.useEffect(() => {
    dispatch(getAllSliders());
  }, []);
  return (
    <div>
      <PageCreator {...pageProps} />
    </div>
  );
};

export default Sliders;
