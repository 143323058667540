import subscriptionApi from "../api/subscriptionApi";
import { subscriptionTypes } from "../types";
import checkIfAsyncReqSuccess from "./checkIfAsyncReqSuccess";


export const getAllSubscriptions = (status, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "",
      errorMessage: "Failed to get categories",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subscriptionTypes.GET_ALL_SUBSCRIPTION,
      payload: {
        request: {
          url: subscriptionApi.GET_ALL_SUBSCRIPTION,
          method: "GET",
          params: {
            status: status,
          }
        },
      },
    });
};

export const createSubscription = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subscription created successfully",
      errorMessage: "Failed to create category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subscriptionTypes.CREATE_SUBSCRIPTION,
      payload: {
        request: {
          url: subscriptionApi.CREATE_SUBSCRIPTION,
          method: "POST",
          data: data
        },
      },
    });
};

export const updateSubscription = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subscription created successfully",
      errorMessage: "Failed to update category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subscriptionTypes.UPDATE_SUBSCRIPTION,
      payload: {
        request: {
          url: subscriptionApi.UPDATE_SUBSCRIPTION,
          method: "PUT",
          data: data
        },
      },
    });
};

export const deleteSubscription = (data, cb, errorCb) => {
  return (dispatch) =>
    checkIfAsyncReqSuccess(dispatch, {
      successMessage: "Subscription deleted successfully",
      errorMessage: "Failed to delete category",
      enableMessage: true,
      cb: cb,
      errorCb: errorCb,
      type: subscriptionTypes.DELETE_SUBSCRIPTION,
      payload: {
        request: {
          url: subscriptionApi.DELETE_SUBSCRIPTION,
          method: "DELETE",
          data: data
        },
      },
    });
};
